import React, { useState } from "react";
import "./addVendor.scss";
import ContactPersonForm from "./contactPerson";
import BankDetailsForm from "./bankDetails";

const AddVendorModal = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState("organization");

  const renderTabContent = () => {
    switch (activeTab) {
      case "organization":
        return (
          <div className="tab-content">
            {/* Organization tab content */}
            <div className="form-group">
              <label>VAT Treatment*</label>
              <select></select>
            </div>
            <div className="form-group">
              <label>VAT IN*</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Source of Supply*</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Company IN</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>Currency</label>
              <select></select>
            </div>
            <div className="form-group">
              <label>Opening Balance</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label>TDS</label>
              <select></select>
            </div>
            <div className="form-group">
              <label>Payment Terms</label>
              <input type="text" />
            </div>
            <div className="form-group">
              <label className="checkbox-label">
                <input type="checkbox" />
                Enable portal access for this vendor
              </label>
            </div>
          </div>
        );
      case "address":
        return (
          <div className="tab-content">
            {/* Address tab content */}
            <div className="address-tab">
              <div className="address-wrapper">
                <div className="address-section">
                  <div className="section-header" style={{ marginBottom: "25px" }}>
                    <h3>Billing Address</h3>
                  </div>
                  <div className="address-form">
                    <div className="form-group">
                      <label>
                        Country/ Region<span>*</span>
                      </label>
                      <select>
                        <option value="">Select Country</option>
                        {/* Add country options here */}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input type="text" placeholder="Enter Address Line 1" />
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <input type="text" placeholder="Enter Address Line 2" />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input type="text" placeholder="Enter City" />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <select>
                        <option value="">Select State</option>
                        {/* Add state options here */}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Zip/ Postal Code</label>
                      <input type="text" placeholder="Enter Zip/Postal Code" />
                    </div>
                  </div>
                </div>
                <div className="address-section">
                  <div className="section-header">
                    <h3>Shipping Address</h3>
                    <div className="same-as-billing">
                      <input type="checkbox" id="sameAsBilling" />
                      <label htmlFor="sameAsBilling">Same as billing address</label>
                    </div>
                  </div>
                  <div className="address-form">
                    <div className="form-group">
                      <label>
                        Country/ Region<span>*</span>
                      </label>
                      <select>
                        <option value="">Select Country</option>
                        {/* Add country options here */}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input type="text" placeholder="Enter Address Line 1" />
                    </div>
                    <div className="form-group">
                      <label>Address Line 2</label>
                      <input type="text" placeholder="Enter Address Line 2" />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input type="text" placeholder="Enter City" />
                    </div>
                    <div className="form-group">
                      <label>State</label>
                      <select>
                        <option value="">Select State</option>
                        {/* Add state options here */}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Zip/ Postal Code</label>
                      <input type="text" placeholder="Enter Zip/Postal Code" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="address-footer">
                <p>⚠️ You can add and manage additional addresses from Vendor Details or Dashboard.</p>
                <div className="button-group">
                  <button className="save-button">Save</button>
                  <button className="cancel-button">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        );
      case "contacts":
        return <ContactPersonForm />;
      case "bank":
        return <BankDetailsForm />;
      default:
        return null;
    }
  };

  return (
    <div className="add-vendor-modal">
      <div className="modal-header">
        <h2>Add New Vendor</h2>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="modal-body">
        <div className="form-row">
          <div className="form-group">
            <label>Primary contact</label>
            <div className="input-group">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
            </div>
          </div>
          <div className="form-group">
            <label>Company Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Vendor Display Name*</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Vendor Email</label>
            <input type="email" />
          </div>
          <div className="form-group">
            <label>Vendor Phone</label>
            <div className="input-group">
              <input type="text" placeholder="Mobile" />
              <input type="text" placeholder="Work Phone" />
            </div>
          </div>
        </div>

        <div className="tabs">
          <div
            className={`tab ${activeTab === "organization" ? "active" : ""}`}
            onClick={() => setActiveTab("organization")}
          >
            Organization
          </div>
          <div className={`tab ${activeTab === "address" ? "active" : ""}`} onClick={() => setActiveTab("address")}>
            Address
          </div>
          <div className={`tab ${activeTab === "contacts" ? "active" : ""}`} onClick={() => setActiveTab("contacts")}>
            Contact Persons
          </div>
          <div className={`tab ${activeTab === "bank" ? "active" : ""}`} onClick={() => setActiveTab("bank")}>
            Bank Details
          </div>
        </div>

        {renderTabContent()}
      </div>

      <div className="modal-footer">
        <button className="add-button">Add Vendor</button>
        <button className="cancel-button">Cancel</button>
      </div>
    </div>
  );
};

export default AddVendorModal;
