import React, { useState } from 'react';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { request } from '../../services';
import Exported from '../../services/endpoints';
import { useSelector } from 'react-redux';

const CreateNewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [attemptsLeft, setAttemptsLeft] = useState(3);
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    capital: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  const { handleSubmit, formState: { errors } } = useForm({ mode: 'all' });
  const { referenceNumber } = useParams();
  const email = useSelector((state) => state.auth.email);

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const capital = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidations({
      length,
      number,
      capital,
      specialChar,
    });

    return length && number && capital && specialChar;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmitForm = async (event) => {
    if (!newPassword || !confirmPassword || !otp) {
      alert("Please fill in all fields");
      return;
    }

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!validatePassword(newPassword)) {
      alert("Password does not meet the requirements");
      return;
    }

    try {
      const res = await request({
        url: "auth/confirmPasswordReset",
        method: Exported.APIMethods.POST,
        data: {
          newPassword: newPassword,
          otp: otp,
          otpReference: referenceNumber,
          email: email
        },
      });
      if (res.data.success) {
        navigate("/passwordReset/success");
      } else {
        setAttemptsLeft(prev => prev - 1);
        setError("Incorrect OTP");
      }
    } catch (err) {
      setAttemptsLeft(prev => prev - 1);
      setError("Incorrect OTP");
    }
  };

  return (
    <div className="new-password-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Create new password</h1>

        <form className="form-wrapper" onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="input-wrapper">
            <div className="password-input-wrapper">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter new password"
                className="input-field"
                value={newPassword}
                onChange={handlePasswordChange}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                required
              />
              <span className="tooltip-icon-1" onClick={togglePasswordVisibility}>
                {passwordVisible ? "🙈" : "👁️"}
              </span>
              {showTooltip && (
                <div className="password-tooltip">
                  <p>Your password must contain</p>
                  <ul>
                    <li className={passwordValidations.length ? "valid" : ""}>8 or more characters</li>
                    <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
                    <li className={passwordValidations.capital ? "valid" : ""}>1 Capital and 1 Small letter</li>
                    <li className={passwordValidations.specialChar ? "valid" : ""}>At least 1 special character (@,#,$,!, etc.)</li>
                  </ul>
                </div>
              )}
            </div>
            <div className="password-input-wrapper">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="Confirm new password"
                className="input-field"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <span className="tooltip-icon" onClick={toggleConfirmPasswordVisibility}>
                {confirmPasswordVisible ? "🙈" : "👁️"}
              </span>
            </div>
            <input
              type="text"
              placeholder="Enter OTP sent to registered e-mail"
              className={`input-field ${error && 'error'}`}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
          </div>
          {error && 
          <div className="error-message">
            {error}. You’ve {attemptsLeft} more attempts left!
          </div>}
          <div onClick={() => navigate("/passwordReset/email")} className='resend-code'>Resend Code</div>
          <button type="submit" className="continue-button">Continue</button>
        </form>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default CreateNewPassword;