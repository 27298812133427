import { createSlice } from "@reduxjs/toolkit";

export const authSignupSlice = createSlice({
  name: "auth",
  initialState: {
    id: "",
    email: "",
    referenceNumber: "",
    password: "",
    name: "",
    lastname: "",
    dateOfBirth: "",
    country: "",
    selectedBusiness: {},
    userStatus: "",
  },
  reducers: {
    saveEmail: (state, action) => {
      state.email = action.payload;
    },
    saveReferenceNumber: (state, action) => {
      state.referenceNumber = action.payload;
    },
    savePassword: (state, action) => {
      state.password = action.payload;
    },
    saveCountry: (state, action) => {
      state.country = action.payload;
    },
    saveName: (state, action) => {
      state.name = action.payload;
    },
    saveLastname: (state, action) => {
      state.lastname = action.payload;
    },
    saveDateOfBirth: (state, action) => {
      state.dateOfBirth = action.payload;
    },
    saveID: (state, action) => {
      state.id = action.payload;
    },
    saveSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },
    saveOrgID: (state, action) => {
      state.orgId = action.payload;
    },
    saveUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
  },
});

export const {
  saveEmail,
  saveReferenceNumber,
  savePassword,
  saveCountry,
  saveName,
  saveLastname,
  saveDateOfBirth,
  saveID,
  saveSelectedBusiness,
  saveOrgID,
  saveUserStatus,
} = authSignupSlice.actions;

export default authSignupSlice.reducer;
