import React from "react";
import "./styles.scss";
import dataIcon from "../../assets/icons/admin/data.png";
import generalIcon from "../../assets/icons/admin/general.png";
import collectionIcon from "../../assets/icons/admin/collections.png";
import templateIcon from "../../assets/icons/admin/templates.png";
import paymentIcon from "../../assets/icons/admin/payments.png";
import developerIcon from "../../assets/icons/admin/developer.png";

const Settings = () => {
  return (
    <div className="settings-container">
      <div className="header">
        <h2>All Settings</h2>
        <div className="search-box">
          <input type="text" placeholder="Search settings" />
        </div>
      </div>
      <div className="settings-grid">
        <div className="settings-card">
          <div className="settings-category">
            <div className="icon general-icon">
              <img src={generalIcon} />
            </div>
            <h3>General</h3>
          </div>
          <ul>
            <li>Profile</li>
            <li>Reset Password</li>
            <li>Roles</li>
            <li>Users</li>
            <li>Customization</li>
            <li>Remove Account</li>
            <li>Subscription</li>
          </ul>
        </div>

        <div className="settings-card">
          <div className="settings-category">
            <div className="icon payments-icon">
              <img src={paymentIcon} />
            </div>
            <h3>Payments</h3>
          </div>
          <ul>
            <li>Default Accounts</li>
            <li>Scheduled Payments</li>
            <li>Pay-runs</li>
            <li>Hold all payments</li>
            <li>Workflow</li>
            <li>Reconciliations</li>
            <li>Payroll</li>
            <li>Spent Management</li>
          </ul>
        </div>

        <div className="settings-card">
          <div className="settings-category">
            <div className="icon collections-icon">
              <img src={collectionIcon} />
            </div>
            <h3>Collections</h3>
          </div>
          <ul>
            <li>Default Accounts</li>
            <li>Auto-Scheduling</li>
            <li>Reminders</li>
            <li>Customer Data</li>
            <li>Reconciliations & Sync</li>
          </ul>
        </div>

        <div className="settings-card">
          <div className="settings-category">
            <div className="icon data-forecast-icon">
              <img src={dataIcon} />
            </div>
            <h3>Data & Forecast</h3>
          </div>
          <ul>
            <li>Insights</li>
            <li>Dynamic Forecast</li>
            <li>Budgeting</li>
          </ul>
        </div>

        <div className="settings-card">
          <div className="settings-category">
            <div className="icon templates-icon">
              <img src={templateIcon} />
            </div>
            <h3>Templates</h3>
          </div>
          <ul>
            <li>E-mail</li>
            <li>Invoices</li>
            <li>Statements</li>
            <li>Bills</li>
          </ul>
        </div>

        <div className="settings-card">
          <div className="settings-category">
            <div className="icon developer-data-icon">
              <img src={developerIcon} />
            </div>
            <h3>Developer & Data</h3>
          </div>
          <ul>
            <li>Developer Tools</li>
            <li>Integrations</li>
            <li>Data Backup</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Settings;
