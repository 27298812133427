import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveCountry } from '../../redux/reducers/authSignupSlice';

const Country = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDropdownChange = (event) => {
    let country = event.target.value
    if (country) {
      dispatch(saveCountry(country));
      navigate('/authSignup/email');
    }

    console.log("hhhhhhh")
  };

  return (
    <div className="country-incorporation-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Country of Incorporation</h1>
        <p>Let’s us know where your company is incorporated.</p>
        
        <div className="dropdown-wrapper">
          <select className="dropdown" onChange={handleDropdownChange}>
            <option value="" disabled selected>Select your country</option>
            <option value="United Kingdom">United Kingdom</option>
            {/* Add more country options as needed */}
          </select>
        </div>
      </div>
      <footer className="footer">
          <p className="footer-company">PennyFlo Limited • United Kingdom</p>
          <div className="footer-links">
            <p>Privacy Policy</p>
            <p>Terms of Use</p>
          </div>
        </footer>
    </div>
  );
};

export default Country;
