import React, { useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveDateOfBirth, saveUserStatus } from "../../redux/reducers/authSignupSlice";
import { request } from "../../services";
import Exported from "../../services/endpoints";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";

const DateOfBirthSignup = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.authSignup.name);
  const lastName = useSelector((state) => state.authSignup.lastname);
  const id = useSelector((state) => state.authSignup.id);

  const userDetails = async (data) => {
    try {
      const res = await request({
        url: "user",
        method: Exported.APIMethods.PUT,
        data: data,
      });
      console.log("emailOtp response:", res);
      console.log("data:", data);
      console.log("id:", id);
      return res;
    } catch (err) {
      console.error("Error sending OTP", err);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!dateOfBirth) {
      alert("Please enter your date of birth");
      return;
    }

    let data = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth.toISOString().split("T")[0],
    };

    dispatch(saveDateOfBirth(dateOfBirth.toISOString().split("T")[0]));

    let res = await userDetails(data);

    let userStatus = res.data.user?.status;
    dispatch(saveUserStatus(userStatus));

    navigate("/authSignup/business");
  };

  return (
    <div className="dob-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Date of Birth</h1>
        <p>You must be 18 or older to open a PennyFlo account</p>

        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="dob-input-wrapper">
            <DatePicker
              selected={dateOfBirth}
              onChange={(date) => setDateOfBirth(date)}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              className="dob-input"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              popperPlacement="right-start"
              highlightDates={[]}
              required
              customInput={
                <div className="custom-input">
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    value={dateOfBirth ? dateOfBirth.toLocaleDateString("en-GB") : ""}
                    placeholder="DD/MM/YYYY"
                    readOnly
                  />
                  <span className="calendar-icon">
                    <FaCalendarAlt size={25} />
                    {/* <BsCalendarDate /> */}
                  </span>
                </div>
              }
            />
          </div>
          <button type="submit" className="continue-button">
            Continue
          </button>
        </form>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default DateOfBirthSignup;
