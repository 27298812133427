import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { REGEX } from "../../utils";
import {
  login,
  saveAccessToken,
  saveEmail,
  saveOrganisationId,
  saveOtpRef,
  savePassword,
  saveRememberMe,
  saveUser,
  saveUserData,
  saveUserId,
} from "../../redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../components/Toast";

const Login = () => {
  let navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const rememberMeSelector = useSelector((state) => state.auth?.isRememberMe);
  const [rememberMe, setRememberMe] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleEmailChange = async (event) => {
    setSuccess(true);
  };

  useEffect(() => {
    dispatch(saveAccessToken(null));
    if (rememberMeSelector) {
      setRememberMe(true);
    }
  }, [dispatch, rememberMeSelector]);

  const onSubmit = (data) => {
    let request = {
      email: data.email,
      password: data.password,
    };
    login(request)
      .then((res) => {
        if (res.data.mfaEnabled) {
          dispatch(saveOtpRef(res.data.otpReferenceNumber));
          dispatch(saveEmail(data.email));
          dispatch(savePassword(data.password));
          navigate("/auth/otp");
        } else {
          const token = res.data?.sessionToken;
          const userData = res.data?.data;
          const user = res.data?.user;
          const organizationId = res.data?.organization?.id;
          const success = res.data?.success;

          if (token) {
            dispatch(saveRememberMe(rememberMe));
            dispatch(saveUserData(userData));
            dispatch(saveAccessToken(token));
            dispatch(saveOrganisationId(organizationId));
            dispatch(saveUser(user));
            dispatch(saveUserId(user.id)); 

            const userStatus = user.status;
            console.log("status:", userStatus);

            if (userStatus === "ACTIVE") {
              navigate("/main/dashboard");
            } else if (userStatus === "ACCOUNT_CREATED") {
              navigate("/authSignup/name");
            } else if (userStatus === "DEMOGRAPHY_UPDATED") {
              navigate("/authSignup/business");
            } else if (userStatus === "ORGANIZATION_CREATED") {
              navigate("/authSignup/verification");
            } else if (userStatus === "DOCUMENTS_UPLOADED") {
              navigate("/authSignup/applicationUnderReview");
            } else if (!success) {
              Toast({
                type: "error",
                message: "Invalid response from server",
              });
            }
          } else {
            Toast({
              type: "error",
              message: "Invalid credentials",
            });
          }
        }
      })
      .catch((err) => {
    console.error("Login error:", err);
    if (err.response?.data?.errorCode === "FAILED-USER-AUTH") {
      Toast({
        type: "error",
        message: "Incorrect Email or Password",
      });
    } else {
      Toast({
        type: "error",
        message: "An error occurred during login. Please try again.",
      });
    }
  });
  };

  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: REGEX.EMAIL_ID,
      message: "Invalid email address",
    },
  };

  const onChangeRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleLogin = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <>
      <form className="col-md-6 login-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-content">
          <p className="content-title">Login</p>
          <p className="login-text">Welcome back, please login to your account</p>
          <div className="login-input-container">
            <label className={`login-input-label ${errors.email !== undefined ? "text-danger" : ""}`}>
              Work Email ID
            </label>
            <NormalInput
              type={"text"}
              onChange={handleEmailChange}
              register={register("email", {
                required: customErrorMessages.required,
                pattern: customErrorMessages.pattern,
              })}
              error={errors.email}
              messages={customErrorMessages}
            />

            <label className={`login-input-label ${errors.password !== undefined ? "text-danger" : ""}`}>
              Password
            </label>
            <NormalInput
              type={"password"}
              register={register("password", {
                required: "Password is required",
              })}
              error={errors.password}
              messages={errors}
            />
          </div>
          <div className="login-checkbox-container">
            <div className="d-flex">
              <NormalInput type={"checkbox"} checkboxInput value={rememberMe} onChange={onChangeRememberMe} />
              <p className="login-checkbox-label">Remember me</p>
            </div>
            <div>
              <p className="login-forgot-text" onClick={() => navigate("/passwordReset/email")}>
                Forgot Password?
              </p>
            </div>
          </div>
          <div className="mt-2">
            <NormalButton label={"Sign In"} loginBtn onKeyDown={handleLogin} />
          </div>
          <div className="mt-3 signup">
            <p className="signup-title">
              Don't have an account? &nbsp;
              <span className="signup-link" onClick={() => navigate("/authSignup/signup")}>
                Signup
              </span>
            </p>
          </div>
          <div className="login-copy-right">
            Copyright 2023 PennyFlo Ltd
            <div className="login-privacy">
              <p>Privacy Policy </p> <p> Terms of Use</p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
