import React, { useEffect } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const PasswordResetSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/auth/login');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="success-screen">
      <div className="content-wrapper">
        <div className="icon-wrapper">
          <div className="checkmark-icon">✔</div>
        </div>
        <h1>Hurray 🎉 Your account password reset is successful.</h1>
        <p>You can now use your new password to log in to your account. This page will automatically redirect to the login page in 5 seconds.</p>
        <a href="/auth/login" className="login-link">Click here to proceed to the login page</a>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default PasswordResetSuccess;
