import React ,{useState} from 'react'
import './styles.scss' 
import closeIcon from '../../assets/icons/close-grey.png'; // Your close icon
import uploadIcon from '../../assets/icons/upload.png'; // Your upload icon

function Categorize({ onClose }) {
  const [category, setCategory] = useState('Debt');
  const [account, setAccount] = useState('Short term liability');
  const [expenseType, setExpenseType] = useState('Goods');
  const [date, setDate] = useState('10 Apr 2024');
  const [amount, setAmount] = useState('£1,200.00 Cr');
  const [vendor, setVendor] = useState('Innova Corp');
  const [invoiceNumber, setInvoiceNumber] = useState('C596619B');
  const [description, setDescription] = useState('NEFT/IMPS/600216579876/2425860/InnovaCorp/HDFC0001100/Loan');
  const [attachFiles, setAttachFiles] = useState(null);
  const [isReverseCharge, setIsReverseCharge] = useState(true);
  const [selectedType, setSelectedType] = useState('');

  return (
    <div className="categorize-modal">
    <div className="modal-content">
      <div className="modal-header">
        <h1>Categorize manually</h1>
        <img src={closeIcon} alt="Close" className="close-icon" onClick={onClose} />
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label>Category<span>*</span></label>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="Debt">Debt</option>
            {/* Add more options here */}
          </select>
        </div>
        <div className="form-group">
          <label>Account<span>*</span></label>
          <select value={account} onChange={(e) => setAccount(e.target.value)}>
            <option value="Short term liability">Short term liability</option>
            {/* Add more options here */}
          </select>
        </div>
        <div className="form-group">
          <label>Expense Type<span>*</span></label>
          <div className="radio-group">
            <div 
              className="radio-items" 
              onClick={() => setSelectedType('Goods')}
            >
              <div className={`circle ${selectedType === 'Goods' ? 'active' : ''}`}></div>
              <h1>Goods</h1>
            </div>
            <div 
              className="radio-items" 
              onClick={() => setSelectedType('Service')}
            >
              <div className={`circle ${selectedType === 'Service' ? 'active' : ''}`}></div>
              <h1>Service</h1>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Date<span>*</span></label>
          <input 
            type="text" 
            value={date} 
            onChange={(e) => setDate(e.target.value)} 
            className="input-readonly" 
            readOnly 
          />
        </div>
        <div className="form-group">
          <label>Amount</label>
          <input 
            type="text" 
            value={amount} 
            onChange={(e) => setAmount(e.target.value)} 
            className="input-readonly amount-input" 
  
          />
        </div>
        <div className="form-group">
          <label>Vendor<span>*</span></label>
          <select value={vendor} onChange={(e) => setVendor(e.target.value)}>
            <option value="Innova Corp">Innova Corp</option>
            {/* Add more options here */}
          </select>
        </div>
        <div className="form-group">
          <label>Invoice #</label>
          <input 
            type="text" 
            value={invoiceNumber} 
            onChange={(e) => setInvoiceNumber(e.target.value)} 
            className="input-readonly" 
            readOnly 
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            className="input-textarea" 
            rows="4"
          />
        </div>
        <div className="form-group">
          <label>Attach Files</label>
          <div className="file-upload">
            <input 
              type="file" 
              onChange={(e) => setAttachFiles(e.target.files[0])} 
              className="file-input" 
            />
            <img src={uploadIcon} alt="Upload" className="upload-icon" />
          </div>
          
        </div>
        <span className='file-attach'>{attachFiles ? attachFiles.name : ".pdf, or image files only. (Maximum 10 MB)"}</span>
      </div>
      <div className="modal-footer">
      <div className=" reverse-charge">
          <label>
            <input 
              type="checkbox" 
              checked={isReverseCharge} 
              onChange={(e) => setIsReverseCharge(e.target.checked)} 
            />
            This transaction is applicable for reverse charge.
          </label>
        </div>
        <button className="save-btn">Save</button>
      </div>
    </div>
  </div>
  )
}

export default Categorize