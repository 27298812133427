import React ,{useLayoutEffect, useState} from 'react'
import './styles.scss' 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from "../../assets/icons/calendar2.png"
import close from '../../assets/icons/close-grey.png'
import check from '../../assets/icons/Check.png'

function Revoke({onClose}) {
  const [startDate, setStartDate] = useState(new Date());
  const [isVerifyPopupOpen, setIsVerifyPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  
  const toggleVerifyPopup = () => {
    setIsVerifyPopupOpen(!isVerifyPopupOpen);
  };

  const toggleSuccessPopup = () => {
    setIsSuccessPopupOpen(!isSuccessPopupOpen);
  };

  return (
        <div className="reschedule-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h1>Revoke Payments</h1>
              <span>5 Invoices</span>
            </div>
            <div className="modal-body">
              <div className='modal-subheader'>
                <h4>Invoice Details</h4>
                <h4>Amount</h4>
              </div>
              <div className="invoice-details">
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
              </div>
              <h1 className="view-all">View all</h1>
              <div className='reschedule-date'>
                  <h1>Rescheduled Date<span>*</span></h1>
                  <div>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} popperPlacement="bottom" />
                    <img src={calendarIcon}/>
                  </div>
              </div>
              <div className='reason'>
                <h1>Reason<span>*</span></h1>
                <input
              placeholder="Reason for Rescheduling"
              className="input-textarea"
            />
              </div>
              <div className='notes'>
                <h1>Additional Notes</h1>
                <input
              placeholder="Type here..."
              className="input-textarea"
            />
              </div>
              <div className="payment-details">
                <div className='modal-bottom'>
                  <button className='cancel-btn' onClick={onClose}>Cancel</button>
                  <button className="reschedule-btn" onClick={() => toggleVerifyPopup()}>Confirm</button>
                </div>
              </div>
            </div>
          </div>
          {isVerifyPopupOpen && (
              <div className="modal">
                <div className="modal-content">
                  <div className='modal-header'>
                    <h1>Verify your identity</h1>
                    <img src={close} onClick={() => setIsVerifyPopupOpen(false)}/>
                  </div>
                  <div className='otp-input-wrapper'>
                            <label htmlFor="otp">Enter OTP sent to your e-mail</label>   
                            <input
                            type="text"   
                            placeholder="OTP"
                            id='otp'
                            className="otp-input"
                            required
                            autoComplete="off" 
                            />
                  </div>
                  <div className='verify-btn' onClick={() => toggleSuccessPopup()}>verify</div>
                </div>
              </div>
            )}
            {isSuccessPopupOpen && (
              <div className="success-modal">
                <div className="modal-wrapper">
                  <div className='closeIcon'>
                    {/* <h1>Success!</h1> */}
                    <img src={close} onClick={() => setIsSuccessPopupOpen(false)} alt="Close" />
                  </div>
                  <div className='modal-contents'>
                    <img src={check}/>
                    <h1>Reschedule Successful</h1>
                    <span>Your selected payments have been successfully rescheduled.</span>
                  </div>
                </div>
              </div>
            )}
        </div>
  )
}

export default Revoke