import React, { useState } from "react";
import "./holdPaymentsPopup.scss";

const DeletePopup = ({ onClose }) => {
  const [otpMethod, setOtpMethod] = useState("mobile");
  const [otpCode, setOtpCode] = useState("");

  const handleOtpChange = (event) => {
    setOtpCode(event.target.value);
  };

  const handleOtpMethodChange = (method) => {
    setOtpMethod(method);
  };

  return (
    <div className="popup-container">
      <div className="popup-box">
        <div className="popup-header">
          <h2>Delete Vendor Account</h2>
        </div>
        <div className="popup-body">
          <p className="description">Delete Lago LTD (Vendor ID: LL012-090424-13) permanently.</p>
          <div className="alert">
            <span className="alert-icon">⚠️</span>
            <p>
              Delete all data related to this vendor. This action can’t be undone. You need to add the vendor again to
              access the vendor dashboard. It will not delete the transactions you’ve made already.
            </p>
          </div>
          <div className="otp-method">
            <label>Get OTP over</label>
            <div className="otp-options">
              <label>
                <input type="radio" checked={otpMethod === "email"} onChange={() => handleOtpMethodChange("email")} />
                E-Mail
              </label>
              <label>
                <input type="radio" checked={otpMethod === "mobile"} onChange={() => handleOtpMethodChange("mobile")} />
                Mobile
              </label>
            </div>
          </div>
          <p className="otp-message">Enter OTP sent to your registered mobile +44 ** **** **67</p>
          <input type="text" className="otp-input" value={otpCode} onChange={handleOtpChange} placeholder="Enter OTP" />
        </div>
        <div className="popup-actions">
          <div>
            <button className="proceed-btn">Proceed</button>
          </div>
          <div>
            <button className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
