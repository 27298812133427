// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
  gap: 156px;
}
.success-screen .success-message {
  font-size: 2rem;
  color: #333;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
}
.success-screen .success-message .highlight {
  color: #6c63ff;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
}
.success-screen .note {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  color: #909090;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/successMessage/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;AACJ;AACI;EACI,eAAA;EACA,WAAA;EAEA,kCAAA;EACA,kBAAA;EACA,gBAAA;AAAR;AAGQ;EACI,cAAA;EAEA,eAAA;EACA,kCAAA;EACA,kBAAA;EACA,gBAAA;AAFZ;AAMI;EACI,iCAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AAJR","sourcesContent":[".success-screen {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f5f5f5;\n    text-align: center;\n    gap: 156px;\n\n    .success-message {\n        font-size: 2rem;\n        color: #333;\n        // margin-bottom: 2rem;\n        font-family: \"Poppins\",sans-serif;  \n        font-style: normal;\n        font-weight: 600;\n        \n\n        .highlight {\n            color: #6c63ff;\n            // font-weight: bold;\n            font-size: 2rem;\n            font-family: \"Poppins\",sans-serif;  \n            font-style: normal;\n            font-weight: 600;\n        }\n    }\n\n    .note{\n        font-family: \"Mulish\",sans-serif;  \n        font-style: normal;\n        color: #909090;\n        font-size: 1rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
