import React, { useState, useEffect } from 'react';
import './styles.scss';
import addNewIcon from "../../assets/icons/admin/add-new.png";
import refreshIcon from "../../assets/icons/admin/refresh.png";
import deleteIcon from "../../assets/icons/admin/delete.png";
import syncIcon from "../../assets/icons/admin/refresh.png";
import { request } from '../../services';
import Exported from '../../services/endpoints';
import { useSelector } from 'react-redux';

const IntegrationScreen = () => {
  const [integrations, setIntegrations] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const orgId = useSelector((state) => state.auth.orgId);

  const togglePopup = (integration) => {
    setSelectedIntegration(integration);
    setIsPopupOpen(!isPopupOpen);
  };

  const handleRemoveIntegration = () => {
    setIntegrations(integrations.filter(integration => integration !== selectedIntegration));
    setIsPopupOpen(false);
  };

  const fetchIntegrations = async () => {
    try {
      const res = await request({
        url: "integration/getAllActive",
        method: Exported.APIMethods.GET,
      });
      setIntegrations(res.data);
    } catch (err) {
      console.error("Error fetching integrations", err);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, [orgId]);

  return (
    <div className="integration-screen">
      {showAddNew ? (
        <div className="add-integrations">
          <div className="add-integrations-header">
            <h2 onClick={() => setShowAddNew(!showAddNew)}>Add Integrations</h2>
            <div className="search-box">
              <input type="text" placeholder="Search integrations" />
            </div>
          </div>
          <div className="integrations-grid">
            {integrations.map((integration) => (
              <div className="integration-card" key={integration.id}>
                <img src={integration.imageUrl} alt={integration.name} />
                <h3>{integration.name}</h3>
                <p>{integration.description}</p>
                {integration.active ? (
                  <span className="status connected">Connected</span>
                ) : (
                  <button className="configure-button">Configure</button>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <div className="header-left"></div>
            <div className="header-right">
              <button className="header-button" onClick={() => setShowAddNew(!showAddNew)}>
                <img src={addNewIcon} alt="Add New" />
                Add New
              </button>
              <button className="header-button">
                <img src={refreshIcon} alt="Refresh" />
                Refresh
              </button>
            </div>
          </div>
          <div className="integration-table">
            <div className="table-header">
              <div>Integration Platforms</div>
              <div>Organization ID</div>
              <div>Status</div>
              <div>Sync</div>
            </div>
            <div className='table-row-wrapper'>
              {integrations.map((integration, index) => (
                <div className="table-row" key={index}>
                  <div className="integration-info">
                    <img src={integration.imageUrl} alt={integration.platform} className="integration-logo" />
                    <span>{integration.name}</span>
                  </div>
                  <div>{integration.id}</div>
                  <div className={`status ${ integration.active? "connected": ""}`}>connected</div>
                  <div className="sync">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                    <span>Last synced on 26.03.2024, 12:00 PM</span>
                    <img src={syncIcon} alt="Sync" className="sync-icon" />
                    <img src={deleteIcon} alt="Delete" className="delete-icon" onClick={() => togglePopup(integration)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {isPopupOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2>Remove {selectedIntegration.platform} integration?</h2>
                <p>Confirming this will remove all the associated data from that platform. You need to integrate and sync again if you need to connect this tool again.</p>
                <div className="modal-actions">
                  <button type="button" className="proceed-button" onClick={handleRemoveIntegration}>Yes, Remove</button>
                  <button type="button" className="cancel-button" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default IntegrationScreen;
