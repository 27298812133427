import React, { useState } from "react";
import "./styles.scss";
import infoIcon from "../../assets/icons/admin/lamp-charge.png"; // Assume info icon is available

const BankDetailsForm = () => {
  const [bankAccounts, setBankAccounts] = useState([
    {
      accountHolderName: "",
      bankName: "",
      accountNumber: "",
      reEnterAccountNumber: "",
      swiftCode: "",
    },
  ]);

  const handleAddBankAccount = () => {
    setBankAccounts([
      ...bankAccounts,
      {
        accountHolderName: "",
        bankName: "",
        accountNumber: "",
        reEnterAccountNumber: "",
        swiftCode: "",
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedAccounts = [...bankAccounts];
    updatedAccounts[index][field] = value;
    setBankAccounts(updatedAccounts);
  };

  return (
    <div className="bank-details-form">
      <h3>Enter bank details</h3>
      <div className="bank-account-forms">
        {bankAccounts.map((account, index) => (
          <div key={index} className="bank-account-form">
            <div className="form-group">
              <label>Account Holder Name</label>
              <input
                type="text"
                value={account.accountHolderName}
                onChange={(e) => handleInputChange(index, "accountHolderName", e.target.value)}
                placeholder="Account Holder Name"
              />
            </div>
            <div className="form-group">
              <label>Bank Name</label>
              <select value={account.bankName} onChange={(e) => handleInputChange(index, "bankName", e.target.value)}>
                <option value="">Select Bank</option>
                <option value="Bank A">Bank A</option>
                <option value="Bank B">Bank B</option>
              </select>
            </div>
            <div className="form-group">
              <label className="required">Account Number*</label>
              <input
                type="text"
                value={account.accountNumber}
                onChange={(e) => handleInputChange(index, "accountNumber", e.target.value)}
                placeholder="Account Number"
              />
            </div>
            <div className="form-group">
              <label className="required">Re-enter Account Number*</label>
              <input
                type="text"
                value={account.reEnterAccountNumber}
                onChange={(e) => handleInputChange(index, "reEnterAccountNumber", e.target.value)}
                placeholder="Re-enter Account Number"
              />
            </div>
            <div className="form-group">
              <label className="required">SWIFT Code*</label>
              <input
                type="text"
                value={account.swiftCode}
                onChange={(e) => handleInputChange(index, "swiftCode", e.target.value)}
                placeholder="SWIFT Code"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="add-bank-account">
        <button type="button" onClick={handleAddBankAccount}>
          <span>+</span> Add New Bank Account
        </button>
      </div>
      <div className="info-box">
        <img src={infoIcon} alt="Info" />
        <p>
          Initiate payments for your vendors directly from PennyFlo by integrating with one of our partner banks.{" "}
          <a href="#">Set-up Now.</a>
        </p>
      </div>
      <div className="footer-actions">
        <button type="button" className="save-button">
          Save
        </button>
        <button type="button" className="cancel-button">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default BankDetailsForm;
