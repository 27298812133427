import React from 'react';
import './styles.scss';
import authentication from "../../assets/images/authentication.png";
import { useNavigate } from 'react-router-dom';

const EmailSent = () => {
  let navigate = useNavigate();
     

  return (
    <div className="email-sent-screen">
      <div className="content-wrapper">
        <div className="left-section">
          <img src={authentication} alt="Graphics" className="graphics-img" />
        </div>
        <div className="right-section">
          <h2>E-mail sent successfully!</h2>
          <h1>Thanks for providing the details.</h1>
          <p>A link to reset your password with instructions has been sent to the registered e-mail.</p>
          <p>(If you haven't received the email, please <span onClick={()=>navigate("/authSignup/signup")}>Signup</span>)</p>
        </div>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default EmailSent;
