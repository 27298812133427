import React, { useState } from "react";
import "./vendorDetailsModal.scss";
import closeIcon from "../../assets/icons/close.png";
import editIcon from "../../assets/icons/edit.png";
import verifiedIcon from "../../assets/icons/admin/Verified.png";
import locationIcon from "../../assets/icons/admin/location.png";
import globalIcon from "../../assets/icons/admin/global.png";
import moreIcon from "../../assets/icons/admin/more.png";
import callIcon from "../../assets/icons/admin/call-calling.png";
import emailIcon from "../../assets/icons/admin/sms-tracking.png";
import barclaysIcon from "../../assets/icons/barclays.png";
import InvoiceSelection from "./transaction";
import StatementOfAccounts from "./statement";
import EmailModal from "./emailVendor";
import HoldAllPaymentsPopup from "./paymentsPopup";
import InactivePopup from "./inactive";
import DeletePopup from "./deleteVendor";

const VendorDetailsModal = ({ onClose, vendor }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const [transaction, setTransaction] = useState(false);
  const [statement, setStatement] = useState(false);
  const [email, setEmail] = useState(false);
  const [payments, setPayments] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [deleteVendor, setDeleteVendor] = useState(false);

  const handleMoreClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setIsDropdownOpen(false);
    switch (option) {
      case "Vendor Dashboard":
        setDashboard(true);
        setTransaction(false);
        setStatement(false);
        setEmail(false);
        setPayments(false);
        setInactive(false);
        setDeleteVendor(false);
        break; // Add break here

      case "New Transaction":
        setTransaction(true);
        setDashboard(false);
        setStatement(false);
        setEmail(false);
        setPayments(false);
        setInactive(false);
        setDeleteVendor(false);
        break; // Add break here

      case "Generate Statement":
        setStatement(true);
        setTransaction(false);
        setDashboard(false);
        setEmail(false);
        setPayments(false);
        setInactive(false);
        setDeleteVendor(false);
        break; // Add break here

      case "Email Vendor":
        setEmail(true);
        setStatement(false);
        setTransaction(false);
        setDashboard(false);
        setPayments(false);
        setInactive(false);
        setDeleteVendor(false);
        break; // Add break here

      case "Hold all Payments":
        setPayments(true);
        setEmail(false);
        setStatement(false);
        setTransaction(false);
        setDashboard(false);
        setInactive(false);
        setDeleteVendor(false);
        break; // Add break here

      case "Mark as Inactive":
        setInactive(true);
        setPayments(false);
        setEmail(false);
        setStatement(false);
        setTransaction(false);
        setDashboard(false);
        setDeleteVendor(false);
        break; // Add break here

      case "Delete":
        setDeleteVendor(true);
        setInactive(false);
        setPayments(false);
        setEmail(false);
        setStatement(false);
        setTransaction(false);
        setDashboard(false);
        break; // Add break here

      default:
        break; // In case none of the above cases match
    }
  };

  console.log("statement:", statement);

  return (
    <div className="vendor-details-modal">
      <div className="modal-content">
        <div className="modal-header">
          <div className="company-info">
            <h2>LAGO LTD</h2>
            <p>Vendor ID: LL012-090424-13</p>
          </div>
          <div className="header-icons">
            <img src={moreIcon} alt="More" className="more-icon" onClick={handleMoreClick} />
            {isDropdownOpen && (
              <div className="dropdown">
                <div className="dropdown-item" onClick={() => handleOptionClick("Vendor Dashboard")}>
                  Vendor Dashboard
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("New Transaction")}>
                  New Transaction
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("Generate Statement")}>
                  Generate Statement
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("Email Vendor")}>
                  E-Mail Vendor
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("Hold all Payments")}>
                  Hold all Payments
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("Mark as Inactive")}>
                  Mark as Inactive
                </div>
                <div className="dropdown-item" onClick={() => handleOptionClick("Delete")}>
                  Delete
                </div>
              </div>
            )}
            <img src={closeIcon} alt="Close" className="close-icon" onClick={onClose} />
          </div>
        </div>
        {dashboard && (
          <div className="modal-body">
            <div className="left-section">
              {/* Primary Contact */}
              <div className="primary-contact">
                <h3>Primary Contact</h3>
                <div className="contact-info">
                  <p>
                    <img src={emailIcon} alt="Email" /> emili@abccompany.com
                  </p>
                  <p>
                    <img src={callIcon} alt="Phone" /> +44 20 7123 4567
                  </p>
                  <p>
                    <img src={locationIcon} alt="Location" /> 128 City Road, London, United Kingdom, EC1V 2NX, HQ
                  </p>
                  <p>
                    <img src={locationIcon} alt="Remote Location" /> Address line 1, Address line 2, Area, District,
                    State, Country, Pincode
                  </p>
                  <p>
                    <img src={globalIcon} alt="Website" /> www.abccompany.com
                  </p>
                </div>
                <img src={editIcon} alt="Edit" className="edit-icon" />
              </div>

              {/* Bank Details */}
              <div className="bank-details">
                <h3>Bank Account Details</h3>
                <div className="bank-info">
                  <img src={barclaysIcon} alt="Barclays" />
                  <p>
                    Barclays <span>Primary</span>
                  </p>
                  <p>********4321</p>
                  <p>SWIFT: BUKBGB22</p>
                  <p>Lago Limited</p>
                </div>
                <img src={editIcon} alt="Edit" className="edit-icon" />
              </div>

              {/* Record Info */}
              <div className="record-info">
                <h3>Record Info</h3>
                <p>Vendor ID: LL012-090424-13</p>
                <p>Created on: 09 April 2024</p>
                <p>Created by: Karthik Sreedharan</p>
              </div>

              {/* Other Details */}
              <div className="record-info">
                <h3>Other Details</h3>
                <p>Currency: GBP</p>
                <p>Payment: Due on receipt</p>
                <p>VAT ID: GB999 9999 73</p>
                <p>Status: Registered Business</p>
              </div>
            </div>

            <div className="right-section">
              <div className="payables-section">
                <h3>Payables</h3>
                <div className="payables-table">
                  <div className="payables-row header">
                    <div>Currency</div>
                    <div>Outstanding Payables</div>
                    <div>Unused Credits</div>
                  </div>
                  <div className="payables-row">
                    <div>GBP - Pound Sterling</div>
                    <div>£3,444.54</div>
                    <div>£0.00</div>
                  </div>
                </div>
              </div>

              <div className="expenses">
                <h3>Expenses</h3>
                <div className="expenses-chart">
                  <p className="chart-header">
                    <span>Last 1 year</span>
                  </p>
                  <div className="chart">
                    {/* Placeholder for chart, assuming you use a library like Chart.js or Recharts */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {transaction && <InvoiceSelection />}
        {statement && <StatementOfAccounts />}
        {email && <EmailModal />}
        {payments && <HoldAllPaymentsPopup />}
        {inactive && <InactivePopup />}
        {deleteVendor && <DeletePopup />}
      </div>
    </div>
  );
};

export default VendorDetailsModal;
