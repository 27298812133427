import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const Congratulations = () => {
  const navigate = useNavigate();

  const handleProceedClick = () => {
    navigate('/authSignup/verification');
  };

  return (
    <div className="congratulations-screen">
      <div className="message">
        <span className="highlight">Congratulations!</span> You’ve successfully completed <br/> registering your company with PennyFlo.
      </div>
      <button className="proceed-button" onClick={handleProceedClick}>
        Proceed for Verification
      </button>
    </div>
  );
};

export default Congratulations;
