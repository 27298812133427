import axios from "axios";
import LoaderRef from "../components/loader";
import { Toast } from "../components/Toast";
import { getTokenFromStore } from "../utils";

export const request = ({ url, method, data, params, isLoader = true, headers = {} }) =>
  new Promise((resolve, reject) => {
    let token = getTokenFromStore();
    console.log("token:", token);

    if (data instanceof FormData || (data && typeof data["photo"] === "string")) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }

    let config = {
      url: "https://pennyflo-api-dev-penny-api-dev.azuremicroservices.io/" + url,
      method: method,
      data: data ? data : null,
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };

    console.log("config:", config);

    if (params) {
      config.params = params;
      console.log("params:", params);
    }

    if (data == null) {
      delete config["data"];
    }
    showLoader(isLoader);

    axios(config)
      .then((res) => {
        console.log("res");
        console.log(res);
        showLoader(false);
        return resolve(res);
      })
      .catch(({ response }) => {
        showLoader(false);
        if (response) {
          console.log("token:", token);
          let { status, data } = response;
          let { message } = data;
          console.log(status);
          if (status === 403) {
            Toast({ type: "error", message: "session expired" });
            window.location.href = "/auth/login";
          } else {
            Toast({ type: "error", message: message || "An error occurred" });
          }
        } else {
          Toast({ type: "error", message: "Network error" });
        }
        return reject(response);
      });
  });

const showLoader = (status) => {
  if (LoaderRef && LoaderRef.render && LoaderRef.render.defaultProps) {
    LoaderRef.render.defaultProps.setLoaderStatus(status);
  }
};
