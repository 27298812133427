import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useForm } from "react-hook-form";
import { Toast } from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { REGEX } from "../../utils";
import authentication from "../../assets/images/authentication.png";
import Exported from "../../services/endpoints";
import { request } from "../../services";
import { useDispatch } from "react-redux";
import { savePasswordResetEmail } from "../../redux/reducers/authSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "all" });
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setValue("email", e.target.value);
  };

  useEffect(() => {
    register("email", {
      required: "Email is required",
      pattern: {
        value: REGEX.EMAIL_ID,
        message: "Invalid email address",
      },
    });
  }, [register]);

  const passwordReset = async (email) => {
    try {
      const res = await request({
        url: "auth/requestPasswordReset",
        method: Exported.APIMethods.POST,
        params: { email },
      });
      return res;
    } catch (err) {
      console.error("Incorrect email:", err);
      return null;
    }
  };

  const onSubmit = (data) => {
    dispatch(savePasswordResetEmail(email));

    passwordReset(email).then((res) => {
      navigate("/passwordReset/message");
    });
  };

  return (
    <div className="forgot-password-screen">
      <div className="content-wrapper">
        <div className="left-section">
          <img src={authentication} alt="Graphics" className="graphics-img" />
        </div>
        <div className="right-section">
          <h2>Forgot Password?</h2>
          <h1>Reset your password!</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-container">
              <NormalInput
                type="email"
                placeholder="name@company.com"
                onChange={handleEmailChange}
                value={email}
                error={errors.email}
              />
            </div>
            <button type="submit" className="continue-button">
              Proceed
            </button>
          </form>
        </div>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default ForgotPassword;
