import React, { useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { saveID, saveReferenceNumber, saveUserStatus } from "../../redux/reducers/authSignupSlice";
import { useDispatch, useSelector } from "react-redux";
import Exported from "../../services/endpoints";
import { request } from "../../services";
import { saveAccessToken } from "../../redux/reducers/authSlice";

const EmailVerification = () => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.authSignup.email);
  const referenceNumber = useSelector((state) => state.authSignup.referenceNumber);
  const password = useSelector((state) => state.authSignup.password);
  const country = useSelector((state) => state.authSignup.country);

  const emailOtp = async (email) => {
    try {
      const res = await request({
        url: "onboard/sendEmailOtp",
        method: Exported.APIMethods.POST,
        params: { email },
      });
      console.log("emailOtp response:", res);
      return res;
    } catch (err) {
      console.error("Error sending OTP", err);
      return null;
    }
  };

  const signup = async (data) => {
    try {
      const res = await request({
        url: "onboard/signup",
        method: Exported.APIMethods.POST,
        data: data,
      });
      console.log("signup response:", res);
      return res;
    } catch (err) {
      console.error("Error signing up", err);
      return null;
    }
  };

  const arrayToInteger = (arr) => arr.join("");

  const handleChange = async (element, index) => {
    if (isNaN(element.value)) return false;

    const updatedCode = [...code.map((d, idx) => (idx === index ? element.value : d))];
    setCode(updatedCode);

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }

    if (updatedCode.every((val) => val !== "")) {
      const OTP = arrayToInteger(updatedCode);
      const data = {
        email: email,
        password: password,
        otpReference: referenceNumber,
        otp: OTP,
        country: country,
      };

      const res = await signup(data);
      const sessionToken = res.data.sessionToken;
      const errorMessage = res.data.errorMessage;
      let userStatus = res.data.user?.status;
      const id = res.data.user?.id;

      if (sessionToken) {
        dispatch(saveID(id));
        dispatch(saveAccessToken(sessionToken));
        dispatch(saveUserStatus(userStatus));
        navigate("/authSignup/name");
      } else {
        alert(errorMessage ? errorMessage : "Incorrect OTP");
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handleResendCode = async () => {
    const otpRes = await emailOtp(email);
    dispatch(saveReferenceNumber(otpRes.data.referenceNumber));
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === code.length && /^[0-9]+$/.test(paste)) {
      const updatedCode = paste.split("");
      setCode(updatedCode);
      document.querySelectorAll(".code-input").forEach((input, index) => {
        input.value = updatedCode[index];
      });

      const OTP = arrayToInteger(updatedCode);
      let data = {
        email: email,
        password: password,
        otpReference: referenceNumber,
        otp: OTP,
        country: country,
      };

      signup(data)
        .then((res) => {
          let sessionToken = res.data.sessionToken;
          let errorMessage = res.data.errorMessage;
          let userStatus = res.data.user.status;
          let id = res.data.user?.id;

          if (sessionToken) {
            dispatch(saveID(id));
            dispatch(saveAccessToken(sessionToken));
            dispatch(saveUserStatus(userStatus));
            navigate("/authSignup/name");
          } else {
            alert(errorMessage ? errorMessage : "Incorrect OTP");
          }
        })
        .catch((err) => {
          console.error("Error signing up", err);
        });
    }
  };

  return (
    <div className="verification-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Verify your E-mail address</h1>
        <p>Enter the verification code</p>

        <div className="code-inputs" onPaste={handlePaste}>
          {code.map((data, index) => {
            return (
              <input
                key={index}
                type="text"
                className="code-input"
                maxLength="1"
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </div>

        <div className="resend-code">
          <span onClick={handleResendCode}>Resend Code</span>
        </div>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default EmailVerification;
