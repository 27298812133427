import React, { useState } from "react";
import "./transaction.scss";

const invoicesData = [
  {
    invoiceNumber: "INV24-018",
    date: "09-04-2024",
    dueDate: "16-04-2024",
    amount: "£1030.00",
    status: "Unpaid",
  },
  {
    invoiceNumber: "INV24-017",
    date: "08-04-2024",
    dueDate: "15-04-2024",
    amount: "£780.87",
    status: "Unpaid",
  },
  {
    invoiceNumber: "INV24-015",
    date: "08-04-2024",
    dueDate: "15-04-2024",
    amount: "£890.39",
    status: "Unpaid",
  },
  {
    invoiceNumber: "INV24-012",
    date: "07-04-2024",
    dueDate: "14-04-2024",
    amount: "£743.28",
    status: "Unpaid",
  },
];

const InvoiceSelection = () => {
  const [selectedTab, setSelectedTab] = useState("Unpaid");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showAllInvoices, setShowAllInvoices] = useState(false); // State to control showing all invoices

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleInvoiceSelection = (invoice) => {
    const isSelected = selectedInvoices.find((inv) => inv.invoiceNumber === invoice.invoiceNumber);
    if (isSelected) {
      setSelectedInvoices(selectedInvoices.filter((inv) => inv.invoiceNumber !== invoice.invoiceNumber));
    } else {
      setSelectedInvoices([...selectedInvoices, invoice]);
    }
  };

  const isInvoiceSelected = (invoice) => {
    return selectedInvoices.find((inv) => inv.invoiceNumber === invoice.invoiceNumber);
  };

  const toggleShowAllInvoices = () => {
    setShowAllInvoices((prev) => !prev);
  };

  return (
    <div className={`invoice-selection ${selectedInvoices.length > 0 ? "with-payment-section" : ""}`}>
      <div className="left-section">
        <h2>Select Invoices to Pay</h2>
        <div className="tabs">
          <span className={`tab ${selectedTab === "Unpaid" ? "active" : ""}`} onClick={() => handleTabClick("Unpaid")}>
            Unpaid
          </span>
          <span
            className={`tab ${selectedTab === "Scheduled" ? "active" : ""}`}
            onClick={() => handleTabClick("Scheduled")}
          >
            Scheduled
          </span>
          <span className={`tab ${selectedTab === "Paid" ? "active" : ""}`} onClick={() => handleTabClick("Paid")}>
            Paid
          </span>
        </div>

        <div className="invoice-table">
          <div className="table-header">
            <div>Invoice Number</div>
            <div>Date</div>
            <div>Due Date</div>
            <div>Amount</div>
            <div>Status</div>
          </div>
          <div className="table-row-wrapper">
            {invoicesData.map((invoice, index) => (
              <div className="table-row" key={index}>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    checked={isInvoiceSelected(invoice)}
                    onChange={() => handleInvoiceSelection(invoice)}
                  />
                  <div>
                    <a href="#">{invoice.invoiceNumber}</a>
                  </div>
                </div>
                <div>{invoice.date}</div>
                <div>{invoice.dueDate}</div>
                <div>{invoice.amount}</div>
                <div className="status unpaid">{invoice.status}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedInvoices.length > 0 && (
        <div className="payment-section">
          <h3>Ready to Pay</h3>
          <p>{selectedInvoices.length} Invoices</p>

          <div className="invoice-details">
            {selectedInvoices.slice(0, showAllInvoices ? selectedInvoices.length : 1).map((invoice) => (
              <div className="invoice-item" key={invoice.invoiceNumber}>
                <span>{invoice.invoiceNumber}</span>
                <span>{invoice.amount}</span>
              </div>
            ))}
            {selectedInvoices.length > 1 && (
              <a href="#" onClick={toggleShowAllInvoices}>
                {showAllInvoices ? "View less" : "View all"}
              </a>
            )}
          </div>

          <div className="payment-options">
            <label>
              <input type="radio" name="paymentOption" defaultChecked /> Pay selected invoices now
            </label>
            <label>
              <input type="radio" name="paymentOption" /> Schedule Payments
            </label>
            <select>
              <option>Select bank account</option>
            </select>
          </div>

          <div className="total-amount">
            <p>Total amount (incl. TAX)</p>
            <p>
              £{selectedInvoices.reduce((total, inv) => total + parseFloat(inv.amount.replace("£", "")), 0).toFixed(2)}
            </p>
          </div>

          <button className="pay-button">Pay</button>
        </div>
      )}
    </div>
  );
};

export default InvoiceSelection;
