import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, redirectTo, path }) => {
  const token = useSelector((state) => state.auth.accessToken);
  const status = useSelector((state) => state.auth.user?.status);
  console.log("PrivateRoute - Token:", token);
  console.log("status:", status);
  let value;

  if(!token && path == "/main"){
    value = <><Navigate  replace={true} to='/auth/login' />{children}</>;
   return  value
  }

  if (status === "ACTIVE" && path !== "/main") {
    console.log("first")
    value = <Navigate to={redirectTo} />;
  } else {
    value = children;
  }
  return value;
};

export default PrivateRoute;
