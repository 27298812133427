// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #7E57FF;
  text-align: center;
  gap: 2rem;
}
.join-screen .info-circle img {
  width: 3rem;
  height: 3rem;
}
.join-screen .message-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.join-screen .message-content .highlight {
  color: #ffffff;
  font-weight: 600;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
.join-screen .message-content .message {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/screens/joinCompany/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;AACJ;AAEQ;EACI,WAAA;EACA,YAAA;AAAZ;AAIQ;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAFZ;AAIY;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,kCAAA;EACA,kBAAA;AAFhB;AAKY;EACI,eAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,iCAAA;EACA,kBAAA;AAHhB","sourcesContent":[".join-screen {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #7E57FF;\n    text-align: center;\n    gap: 2rem;\n\n    .info-circle{\n        img{\n            width: 3rem;\n            height: 3rem;\n        }\n    }\n\n        .message-content{\n            display: flex;\n            flex-direction: column;\n            justify-content: center;\n            align-items: center;\n            gap: 1rem;\n\n            .highlight {\n                color: #ffffff;\n                font-weight: 600;\n                font-size: 2rem;\n                font-family: \"Poppins\",sans-serif;  \n                font-style: normal;\n            }\n\n            .message {\n                font-size: 1rem;\n                color: #ffffff;\n                margin-bottom: 2rem;\n                font-weight: 400;\n                font-family: \"Mulish\",sans-serif;\n                font-style: normal;\n            }\n        }\n    }    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
