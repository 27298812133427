import React ,{useState} from 'react'
import './styles.scss' 

function PayNow() {

  return (
        <div className="pay-now-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h1>Pay Now</h1>
              <span>5 Invoices</span>
            </div>
            <div className="modal-body">
              <div className='modal-subheader'>
                <h4>Invoice Details</h4>
                <h4>Amount</h4>
              </div>
              <div className="invoice-details">
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 31-06-2024</span>
                    <span>Scheduled Date: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 31-06-2024</span>
                    <span>Scheduled Date: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 31-06-2024</span>
                    <span>Scheduled Date: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
                <div className="invoice-item">
                  <div className='item-left'>
                    <h2>PR-2406-01</h2>
                    <span>Due: 31-06-2024</span>
                    <span>Scheduled Date: 10-06-2024</span>
                  </div>
                  <div className='item-right'>
                    <span>£30,214.12</span>
                  </div>
                </div>
                <h1 className="view-all">View all</h1>
              </div>
              <div className="payment-details">
                <div className='select'>Select bank account</div>
                <div className='modal-bottom'>
                  <div className="total-amount">
                    <span>Total amount (including TAX):</span>
                    <h3>£45,892.78</h3>
                  </div>
                  <button className="pay-now-btn">Pay Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default PayNow