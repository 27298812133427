import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { REGEX } from "../../utils";
import {
  login,
  saveAccessToken,
  saveOrganisationId,
  saveOtpRef,
  saveRememberMe,
  saveUser,
  saveUserData,
} from "../../redux/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../components/Toast";
import { request } from "../../services";
import Exported from "../../services/endpoints";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.emailOtp);
  const password = useSelector((state) => state.auth.passwordOtp);
  const otpRef = useSelector((state) => state.auth.otpRef);
  const [otp, setOtp] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const resendOtp = () => {
    let request = {
      email: email,
      password: password,
    };
    login(request).then((res) => {
      dispatch(saveOtpRef(res.data.otpReferenceNumber));
    });
  };

  const submitOtp = (otp) => {
    return new Promise((resolve, reject) => {
      request({
        url: "auth/submitLoginOtp",
        method: Exported.APIMethods.POST,
        params: {
          otpReferenceNumber: otpRef,
          otp: otp,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onSubmit = (data) => {
    submitOtp(otp)
      .then((res) => {
        const token = res.data?.sessionToken;
        const userData = res.data?.data;
        const user = res.data?.user;
        const organizationId = res.data?.organization?.id;
        const success = res.data?.success;

        if (token) {
          dispatch(saveAccessToken(token));
          dispatch(saveOrganisationId(organizationId));
          dispatch(saveUser(user));

          const userStatus = user.status;

          console.log("status:", userStatus);

          if (userStatus === "ACTIVE") {
            navigate("/main/dashboard");
          } else if (userStatus === "ACCOUNT_CREATED") {
            navigate("/authSignup/name");
          } else if (userStatus === "DEMOGRAPHY_UPDATED") {
            navigate("/authSignup/business");
          } else if (userStatus === "ORGANIZATION_CREATED") {
            navigate("/authSignup/verification");
          } else if (userStatus === "DOCUMENTS_UPLOADED") {
            navigate("/authSignup/applicationUnderReview");
          } else if (!success) {
            Toast({
              type: "error",
              message: "Invalid credentials",
            });
          }
        } else {
          Toast({
            type: "error",
            message: "Invalid response from server",
          });
        }
      })
      .catch((err) => {
        console.error("Login error:", err);
        Toast({
          type: "error",
          message: "Login failed. Please try again later.",
        });
      });
  };

  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: REGEX.EMAIL_ID,
      message: "Invalid email address",
    },
  };

  const handleLogin = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <>
      <form className="col-md-6 login-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-content">
          <p className="content-title">Login</p>
          <p className="login-text">Welcome back, please login to your account</p>
          <div className="login-input-container">
            <label className={`login-input-label`}>OTP</label>
            <NormalInput onChange={(e) => setOtp(e.target.value)} type={"otp"} />
          </div>
          <div className="mt-2">
            <NormalButton label={"Sign In"} loginBtn onKeyDown={handleLogin} />
          </div>
          <div onClick={resendOtp} className="resend-otp">
            Resend OTP
          </div>
          <div className="login-copy-right">
            Copyright 2023 PennyFlo Ltd
            <div className="login-privacy">
              <p>Privacy Policy </p> <p> Terms of Use</p>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
