import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { savePassword } from '../../redux/reducers/authSignupSlice';
import eye from '../../assets/icons/eye.png'
import info from '../../assets/icons/info-circle-blur.png'

const PasswordSignup = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    capital: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleTooltip = () => {
    setShowTooltip(prevState => !prevState);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const capital = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidations({
      length,
      number,
      capital,
      specialChar,
    });

    return length && number && capital && specialChar;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!validatePassword(password)) {
      alert("Password does not meet the requirements");
      return;
    }

    dispatch(savePassword(password));
    navigate("/authSignup/verifyEmail");
  };

  return (
    <div className="password-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Create a Password</h1>
        <p>This can be used to login to your account</p>
        
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="password-input-wrapper">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              className="password-input"
              value={password}
              onChange={handlePasswordChange}
              onFocus={() => setShowTooltip(true)}
              onBlur={() => setShowTooltip(false)}
              required
            />
            <span className="tooltip-icon" onClick={togglePasswordVisibility}>
              <img src={eye}/>
            </span>
            {!password  && <img src={info} className='info' onClick={toggleTooltip} />}
            {showTooltip && (
              <div className="password-tooltip">
                <p>Your password must contain</p>
                <ul>
                  <li className={passwordValidations.length ? "valid" : ""}>8 or more characters</li>
                  <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
                  <li className={passwordValidations.capital ? "valid" : ""}>1 Capital and 1 Small letter</li>
                  <li className={passwordValidations.specialChar ? "valid" : ""}>At least 1 special character (@,#,$,!, etc.)</li>
                </ul>
              </div>
            )}
          </div>
          <div className="password-input-wrapper">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              className="password-input"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            <span className="tooltip-icon" onClick={toggleConfirmPasswordVisibility}>
              <img src={eye}/>
            </span>

          </div>
          <button type="submit" className="continue-button">Continue</button>
        </form>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default PasswordSignup;
