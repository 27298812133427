import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import upload from "../../assets/icons/upload.png";
import { request } from "../../services";
import Exported from "../../services/endpoints";
import { useSelector } from "react-redux";
import info from "../../assets/icons/infoCircle.png";

const DocumentUpload = () => {
  const navigate = useNavigate();
  const id = useSelector((state) => state.authSignup.id);
  const orgId1 = useSelector((state) => state.authSignup.orgId);
  const orgId2 = useSelector((state) => state.auth.orgId);
  let orgId = orgId1 || orgId2;
  const [filesRequired, setFilesRequired] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [files, setFiles] = useState({});
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const requiredFiles = async () => {
    try {
      const res = await request({
        url: "onboardingDocs/required",
        method: Exported.APIMethods.GET,
        params: { organizationId: orgId },
      });
      setFilesRequired(res.data);
      const uploaded = res.data.reduce((acc, file) => {
        acc[file.code] = file.uploaded;
        return acc;
      }, {});
      setUploadedFiles(uploaded);
      checkAllFilesUploaded(uploaded, res.data);
    } catch (error) {
      console.error("Error fetching required documents", error);
    }
  };

  const submitFiles = async () => {
    try {
      const res = await request({
        url: "onboardingDocs/submit",
        method: Exported.APIMethods.POST,
        params: { organizationId: orgId },
      });
      return res;
    } catch (error) {
      console.error("Error submitting required documents", error);
    }
  };

  useEffect(() => {
    requiredFiles();
  }, [orgId]);

  const checkAllFilesUploaded = (uploadedFiles, requiredFiles) => {
    const allUploaded = requiredFiles.every((file) => uploadedFiles[file.code]);
    setAllFilesUploaded(allUploaded);
  };

  const handleFileChange = async (event, key) => {
    const file = event.target.files[0];
    if (file) {
      setFiles({ ...files, [key]: file });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("organizationId", orgId);
      formData.append("allowOverwrite", "false");
      formData.append("uploadedByUserId", id);
      formData.append("documentCode", key);

      try {
        const res = await request({
          url: "onboardingDocs/upload",
          method: Exported.APIMethods.POST,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const updatedUploadedFiles = { ...uploadedFiles, [key]: true };
        setUploadedFiles(updatedUploadedFiles);
        checkAllFilesUploaded(updatedUploadedFiles, filesRequired);
      } catch (error) {
        console.error("Error uploading document", error);
        alert(`Failed to upload ${key}`);
      }
    }
  };

  const handleViewSample = () => {
    window.open("/Letter_of_Authorization_Sample.pdf", "_blank");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let res = await submitFiles();
    if (res.data.success) navigate("/authSignup/applicationunderreview");
  };

  const handleSaveAndExit = async () => {
    let res = await submitFiles();
    if (res && res.data.success) {
      navigate(-1);
    }
  };

  const toggleInfoPopup = () => {
    setShowInfoPopup(!showInfoPopup);
  };

  return (
    <div className="document-upload-screen">
      {/* <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button> */}
      <div className="content-wrapper">
        <h1>Upload following documents</h1>
        <p>We need following documents for completing your KYC</p>

        <form className="form-wrapper" onSubmit={handleSubmit}>
          {filesRequired.map((file) => (
            <div className="input-wrapper" key={file.code}>
              <input type="text" className="input" placeholder={file.name} readOnly />
              {file.code === "LOA" && (
                <span className="view-sample" onClick={handleViewSample}>
                  View Sample
                </span>
              )}
              <label className={`upload-icon ${uploadedFiles[file.code] ? "uploaded" : ""}`}>
                <img src={upload} height={17} />
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={(e) => handleFileChange(e, file.code)}
                  style={{ display: "none" }}
                />
                {uploadedFiles[file.code] && <span className="tooltip">Upload again!</span>}
              </label>
              {file.code === "AUTH_REP_ID" && (
                <>
                  <img src={info} alt="Info" className="info-icon" onClick={toggleInfoPopup} />
                  {showInfoPopup && (
                    <div className="info-popup">
                      <p>Authorized representative’s ID proof can be:</p>
                      <ul>
                        <li>Passport</li>
                        <li>National ID</li>
                        <li>Company ID</li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
          <div className="button-wrapper">
            <button type="button" className="save_exit-button" onClick={handleSaveAndExit}>
              Save & Exit
            </button>
            <button type="submit" className="submit-button" disabled={!allFilesUploaded}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentUpload;
