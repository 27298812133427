const Layout = (props) =>{

    return(
        <>
        <div>{props.children}</div>
        
        </>
    )
}

export default Layout;