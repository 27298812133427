import React, { useState } from "react";
import "./styles.scss";
import OrganizationDetails from "../organisation";
import UserScreen from "../user";
import IntegrationScreen from "../Integrations";
import BankDetailsForm from "../vendorManagement/bankDetails";
import VendorScreen from "../vendorManagement";
import BankAccount from "../bankAccounts";
import Settings from "../settings";

const App = () => {
  const [activeTab, setActiveTab] = useState("Organization");

  const renderContent = () => {
    switch (activeTab) {
      case "Organization":
        return <OrganizationDetails />;
      case "Users":
        return <UserScreen />;
      case "Integrations":
        return (
          <div>
            <IntegrationScreen />
          </div>
        );
      case "Bank Accounts":
        return (
          <div>
            <BankAccount />
          </div>
        );
      case "Vendor Management":
        return (
          <div>
            <VendorScreen />
          </div>
        );
      case "Settings":
        return (
          <div>
            <Settings />
          </div>
        );
      default:
        return <div>Log out</div>;
    }
  };

  return (
    <div className="main-layout">
      <div className="content-wrapper">
        <div className="tabs">
          <div
            className={`tab ${activeTab === "Organization" ? "active" : ""}`}
            onClick={() => setActiveTab("Organization")}
          >
            Organization
          </div>
          <div className={`tab ${activeTab === "Users" ? "active" : ""}`} onClick={() => setActiveTab("Users")}>
            Users
          </div>
          <div
            className={`tab ${activeTab === "Integrations" ? "active" : ""}`}
            onClick={() => setActiveTab("Integrations")}
          >
            Integrations
          </div>
          <div
            className={`tab ${activeTab === "Bank Accounts" ? "active" : ""}`}
            onClick={() => setActiveTab("Bank Accounts")}
          >
            Bank Accounts
          </div>
          <div
            className={`tab ${activeTab === "Vendor Management" ? "active" : ""}`}
            onClick={() => setActiveTab("Vendor Management")}
          >
            Vendor Management
          </div>
          <div className={`tab ${activeTab === "Settings" ? "active" : ""}`} onClick={() => setActiveTab("Settings")}>
            Settings
          </div>
          <div className={`tab ${activeTab === "Log out" ? "active" : ""}`} onClick={() => setActiveTab("Log out")}>
            Log out
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default App;
