import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import assets from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalModal from "../NormalModal";
import { hambergerToggle } from "../../redux/reducers/headerSlice";
import { logout, saveAccessToken } from "../../redux/reducers/authSlice";
import ProfilePopup from "../../screens/profilePopup";

const Header = ({ onToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authority = useSelector((state) => state.auth.user?.authorities[0].authority);
  const [show, setShow] = useState(false);
  const [profilePopupShow, setProfilePopupShow] = useState(false); // State to manage profile popup
  const [menu, setMenu] = useState([]);
  const modalRef = useRef(null);

  const handleToHamberger = () => {
    dispatch(hambergerToggle());
  };

  const handleBellIconClick = () => {
    navigate("/main/dashboard");
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const handle = () => {
    //setShow(!show);
    setProfilePopupShow(!profilePopupShow);
  };

  const handleProfileClick = () => {
    setProfilePopupShow(true); // Show the profile popup
    setShow(false); // Hide the menu modal
  };

  const handleClick = (link, title) => {
    if (title === "Profile") {
      handleProfileClick(); // Handle profile click to show detailed profile popup
    } else {
      if (title === "Logout") {
        dispatch(saveAccessToken(null));
      }
      navigate(link);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const menus = [
      { id: 1, title: "Profile", link: "/main/profile/" },
      { id: 2, title: "Integrations", link: "/main/integration/" },
      { id: 3, title: "Networks", link: "/main/networking" },
      { id: 4, title: "Accounts", link: "/main/accounts" },
      { id: 5, title: "Vendors", link: "/main/vendor" },
      { id: 6, title: "Reminder Setting", link: "/main/notification" },
    ];

    if (authority === "Admin") {
      menus.push({ id: 7, title: "Admin", link: "/main/admin" });
    }
    menus.push({ id: 8, title: "Logout", link: "/auth/login" });
    setMenu([...menus]);
  }, [authority]);

  return (
    <div className="header-container">
      <div className="dashboard-container">
        <div className="dashboard-icon-container">
          <img
            src={assets.Icons.headerDashboard}
            alt="dashboardIcon"
            className="dashboard-icon cursor-pointer"
            onClick={handleToHamberger}
          />
        </div>
        <p className="dashboard-text">Dashboard</p>
      </div>
      <div className="d-flex align-items-center px-2">
        <img src={assets.Icons.bell} alt="bell" className="bell-icon cursor-pointer" onClick={handleBellIconClick} />
        <div className="header-profile-container" onClick={handle}>
          <img src={assets.Icons.headerProfile} alt="headerProfile" className="header-profile cursor-pointer" />
        </div>
      </div>
      <NormalModal show={show} setModal={setShow} customModalClass="profilepop" ref={modalRef}>
        <div className="profile-pop-values">
          {menu.map((item) => (
            <div onClick={() => handleClick(item.link, item.title)} className="cursor-pointer" key={item.id}>
              {item.title}
            </div>
          ))}
        </div>
      </NormalModal>
      {profilePopupShow && <ProfilePopup setShow={setProfilePopupShow} menu={menu} handleClick={handleClick} />}
    </div>
  );
};

export default Header;
