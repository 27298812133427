import React from "react";
import "./styles.scss";
import bg from "../../assets/images/background.png";
import arrowIcon from "../../assets/icons/rightArrow.png";
import profileImage from "../../assets/images/profileImage.png"; // assume the profile image
import adminConsoleIcon from "../../assets/icons/admin-console.png"; // assume the icons
import settingsIcon from "../../assets/icons/settings.png";
import logoutIcon from "../../assets/icons/logout.png";
import referralIcon from "../../assets/icons/referral.png";
import announcementIcon from "../../assets/icons/announcement.png";
import closeIcon from "../../assets/icons/close-circle.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveAccessToken } from "../../redux/reducers/authSlice";

const ProfilePopup = ({ setShow }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setShow(false);
  };

  return (
    <div className="profile-popup-container">
      <div className="profile-wrapper" style={{ backgroundImage: `url(${bg})` }}>
        <div className="profile-popup-header">
          <img src={closeIcon} alt="Close" className="close-icon" onClick={handleClose} />
        </div>
        <div className="profile-popup-content">
          <img src={profileImage} alt="Profile" className="profile-image" />
          <h2>Emili Scott</h2>
          <p className="email">emili@pennyflo.io</p>
          <p className="user-id">User ID: 60027-0234-001 </p>
          <div className="complete-profile">
            <p className="role">Role: Owner </p>
            <span onClick={() => handleNavigate("/main/profile")}>Complete Profile</span>
          </div>
        </div>
      </div>
      <div className="profile-popup-menu">
        <div className="menu-item" onClick={() => handleNavigate("/main/admin")}>
          <img src={adminConsoleIcon} alt="Admin Console" />
          <span>Admin Console</span>
          <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
        </div>
        <div className="menu-item" onClick={() => handleNavigate("/main/settings")}>
          <img src={settingsIcon} alt="Settings" />
          <span>Settings</span>
          <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
        </div>
        <div
          className="menu-item"
          onClick={() => {
            dispatch(saveAccessToken(null));
            handleNavigate("/auth/login");
          }}
        >
          <img src={logoutIcon} alt="Logout" />
          <span>Log out</span>
          <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
        </div>
      </div>
      <div className="profile-popup-referral">
        <img src={referralIcon} alt="Referral" />
        <span>
          Refer and earn 30% of the subscription. <a href="/main/referral">Know more</a>
        </span>
      </div>
      <div className="profile-popup-subscription">
        <div className="subscription-header">
          <span>Subscription</span>
          <a href="/main/manageSubscription" className="manage-link">
            Manage
          </a>
        </div>
        <div className="subscription-details">
          <div className="plan-details">
            <span>Scale-up plan with flexible users</span>
            <span>5 users</span>
          </div>
          <div className="renewal-details">
            <span>Next renewal</span>
            <span>10 Jun 2025</span>
          </div>
        </div>
      </div>
      <div className="profile-popup-announcements">
        <div className="announcements-content">
          <div className="announcements-wrapper">
            <span>Announcements</span>
            <img src={announcementIcon} alt="Announcements" />
          </div>
          <div className="announcement-item">
            <div className="announcement-header">
              <h4>What’s new</h4>
              <span>10 Jul 2024, 10:30 AM</span>
            </div>
            <p>
              Track and filter your notifications. Now filter all the notifications you receive in PennyFlo...{" "}
              <a href="/main/notifications">Try now!</a>
            </p>
          </div>
          <div className="announcement-item">
            <div className="announcement-header">
              <h4>Free webinar</h4>
              <span>06 Jul 2024, 11:00 AM</span>
            </div>
            <p>
              Automate your collections with PennyFlo. Join our upcoming webinar to learn how to optimize your...{" "}
              <a href="/main/webinar">Register now</a>
            </p>
          </div>
          <div className="announcement-item">
            <div className="announcement-header">
              <h4>Finance’24</h4>
              <span>06 Jul 2024, 11:00 AM</span>
            </div>
            <p>
              PennyFlo Finance’24, London. After a total of 10 events, we're now coming to London...{" "}
              <a href="/main/events">Book your ticket now</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
