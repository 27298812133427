import React, { useState, useEffect } from 'react';
import './styles.scss';
import addNewIcon from "../../assets/icons/profile-add.svg";
import refreshIcon from "../../assets/icons/admin/refresh.png";
import holdIcon from "../../assets/icons/admin/hold.svg";
import rescheduleIcon from "../../assets/icons/admin/rescheduleIcon.svg";
import pay from "../../assets/icons/admin/profile-add.png";
import undo from "../../assets/icons/admin/undo.svg";
import categorizeIcon from "../../assets/icons/admin/Categorize-icon.svg";
import syncIcon from "../../assets/icons/admin/refresh.png";
import { request } from '../../services';
import Exported from '../../services/endpoints';
import { useSelector } from 'react-redux';
import moreIcon from "../../assets/icons/admin/more.png";
import trashIcon from "../../assets/icons/admin/trash.svg"
import left from "../../assets/icons/pagination_left_arrow.png"
import right from "../../assets/icons/pagination_right_disable.png"
import close from "../../assets/icons/close-grey.png"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PayNow from '../payNow/index.js'
import Reschedule from '../reschedule/index.js'
import Revoke from '../revoke/index.js'
import Hold from '../hold/index.js'
import Categorize from '../categorize/index.js'


const BankAccount = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [isViewTransaction, setIsViewTransaction] = useState(false);
  const [isViewTransactionPopupOpen, setIsViewTransactionPopupOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [isViewUpcomingPayments, setIsViewUpcomingPayments] = useState(false);
  const [isViewUncategorizedExpenses, setIsViewUncategorizedExpenses] = useState(false);
  const orgId = useSelector((state) => state.auth.orgId);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSelected = (index) => selectedRows.includes(index);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [isPayNowClicked, setIsPayNowClicked] = useState(false);
  const [isRescheduleClicked, setIsRescheduleClicked] = useState(false);
  const [isRevokeClicked, setIsRevokeClicked] = useState(false);
  const [isHoldClicked, setIsHoldClicked] = useState(false);
  const [isCategorizeClicked, setIsCategorizeClicked] = useState(false);


  const handleRemoveBankAccount = () => {
    console.log(selectedBankAccount)
    setBankAccounts(bankAccounts.filter(bankAccount => bankAccount !== selectedBankAccount));
    setIsRemovePopupOpen(false);
  };

  const toggleRemovePopup = (bankAccount) => {
    // console.log(e.target)
    setSelectedBankAccount(bankAccount);
    setIsRemovePopupOpen(!isRemovePopupOpen);
  };

  const fetchBankAccounts = async () => {
    try {
      const res = await request({
        url: "bank/allBanks",
        method: Exported.APIMethods.GET,
      });
      setBankAccounts(res.data);
      console.log(res)
    } catch (err) {
      console.error("Error fetching bankAccounts", err);
    }
  };
  useEffect(() => {
    fetchBankAccounts();
  }, [orgId]);
  

  const fetchTransactions = async () => {
    try {
      const res = await request({
        url: "bank/allTransactions",
        method: Exported.APIMethods.GET,
      });
      
      const formattedTransactions = res.data.map(transaction => ({
        ...transaction,
        date: formatDate(transaction.date),  // Format the date
      }));
      
      setTransactions(formattedTransactions);
    } catch (err) {
      console.error("Error fetching transaction data", err);
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  
    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    if (isViewTransaction ) {
      fetchTransactions();
    }
  }, [isViewTransaction, orgId]);

  useEffect(() => {
    if (isViewUncategorizedExpenses ) {
      fetchTransactions();
    }
  }, [isViewUncategorizedExpenses, orgId]);

  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const togglePopup = (event) => {
    const { top, left } = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top, left });
    setIsMorePopupOpen(!isMorePopupOpen);
  };

  const toggleViewTransactionPopup = (bankAccount) => {
    setSelectedBankAccount(bankAccount);
    setIsViewTransaction(true)
    setIsViewTransactionPopupOpen(!isViewTransactionPopupOpen);
  };
  const toggleViewUpcomingPayments = (bankAccount) => {
    setSelectedBankAccount(bankAccount);
    setIsViewUpcomingPayments(true);
    setIsViewTransaction(false); 
  };
  const toggleViewUncategorizedExpenses = (bankAccount) => {
    setSelectedBankAccount(bankAccount);
    setIsViewUncategorizedExpenses(true);
    setIsViewTransaction(false); // Close other views if they are open
    setIsViewUpcomingPayments(false);
    console.log('Uncategorized Expenses view toggled:', bankAccount);
  };


  const handleRowClick = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((row) => row !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };


  const handleCheckboxChange = (index) => {
    let updatedSelections = [...selectedPayments];
  
    if (updatedSelections.includes(index)) {
      updatedSelections = updatedSelections.filter((i) => i !== index);
    } else {
      updatedSelections.push(index);
    }
  
    setSelectedPayments(updatedSelections);
  };


  const handleActionClick = (action) => {
    setIsPayNowClicked(action === 'payNow');
    setIsRescheduleClicked(action === 'reschedule');
    setIsRevokeClicked(action === 'revoke');
    setIsHoldClicked(action === 'hold');
  };
  const closeModal = () => {
    setIsCategorizeClicked(false);
  };


  return (
    <div className="bankAccount-screen">
        {isViewTransaction && selectedBankAccount ? (
        <div className="transaction-view">
          <div className="transaction-header">
            <div className="bank-info">
              <img src={`data:image/png;base64,${selectedBankAccount.logo}`} alt={selectedBankAccount.name} className="bank-logo" />
              <div className="bank-details">
                <h2>{selectedBankAccount.name}</h2>
                <p>xxxxxxxxxxx2244</p>
                <p>Connection ID: {selectedBankAccount.connectionId}</p>
              </div>
            </div>
            <div className="balance-info">
              <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
              <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
              <select className="transaction-filter">
                <option>All Transactions</option>
                <option>Loans</option>
                <option>Deposits</option>
                <option>Withdrawals</option>
              </select>
              <div className="available-balance">
                <span>Available Balance</span>
                <div>£12,775.46</div>
              </div>
            </div>
          </div>
          <div className="transaction-table">
            <div className="table-header">
              <div className="date-time">Date & Time</div>
              <div className="utr" >UTR</div>
              <div className="description">Transaction Description</div>
              <div className="category">Category</div>
              <div className="amount">Amount</div>
              <div className="balance">Balance</div>
            </div>
            <div className="table-body">
              {transactions.map((transaction, index) => (
                <div className="table-row" key={index}>
                  <div className="date-time">{transaction.date}</div>
                  <div className="utr">{transaction.utr}</div>
                  <div className="description">{transaction.description}</div>
                  <div className="category">{transaction.category}</div>
                  <div className={`amount ${transaction.amount < 0 ? 'negative' : 'positive'}`}>
                    {transaction.amount < 0 ? '-' : '+'}£{Math.abs(transaction.amount).toFixed(2)}
                  </div>
                  <div className="balance">£{transaction.accountBalance.toFixed(2)}</div>
              </div>
              
              ))}
            </div>
            <div className="pagination">
              <span>Export Options</span>
              <div>
                <img src={left} className="pagination-arrow"/>
                <h1>Previous</h1>
                <span>1</span>
                <h1>Next</h1>
                <img src={right} className="pagination-arrow"/>
              </div>
            </div>
          </div>
        </div>

        ) : isViewUpcomingPayments && selectedBankAccount ? (
          <div
            className={`upcoming-payments-view 
            ${isPayNowClicked ? 'pay-now-active' : ''} 
            ${isRescheduleClicked ? 'reschedule-active' : ''} 
            ${isRevokeClicked ? 'revoke-active' : ''} 
            ${isHoldClicked ? 'hold-active' : ''}`}>

            <div className="upcoming-payments-header">
              <div className="bank-info">
                <img src={`data:image/png;base64,${selectedBankAccount.logo}`} alt={selectedBankAccount.name} className="bank-logo" />
                <div className="bank-details">
                  <h2>{selectedBankAccount.name}</h2>
                  <p>xxxxxxxxxxx2244</p>
                  <p>Connection ID: {selectedBankAccount.connectionId}</p>
                </div>
              </div>
              <div className="balance-info">
                <img src={close} onClick={()=> {setIsViewUpcomingPayments(false)}}/>
                  <div className='payment-data'>
                    <span>Total Upcoming Payments:</span>
                    <h3>£48,777.18</h3>
                  </div>
              </div>
            </div>
            <div>
              {selectedPayments.length > 0 ? (
                <div className="action-buttons">
                  <div  className='button-wrapper' onClick={() => handleActionClick('payNow')} >
                    <img src={pay} />
                    <div className='hold-message' >Pay Now</div>
                  </div>
                  <div  className='button-wrapper' onClick={() => handleActionClick('reschedule')}>
                    <img src={rescheduleIcon} />
                    <div className='hold-message'>Reschedule</div>
                  </div>
                  <div  className='button-wrapper' onClick={() => handleActionClick('revoke')}>
                    <img src={undo} />
                    <div className='hold-message'>Revoke</div>
                  </div>
                  <div className='button-wrapper' onClick={() => handleActionClick('hold')}>
                    <img src={holdIcon} />
                    <div className='hold-message'>Hold</div>
                  </div>

                </div>
              ) : (
                <>
                  <div  className='button-wrapper'>
                    <img src={holdIcon} />
                    <div className='hold-message'>Hold all Payments</div>
                  </div>
                </>
              )}
            </div>

            <div className="upcoming-payments-table">
              <div className="table-header">
                {/* <div></div> */}
                <div>Bill Number</div>
                <div>Vendor/ Name of Payrun</div>
                <div>Scheduled Date</div>
                <div>Approver</div>
                <div>Amount</div>
              </div>
              <div className="table-body">
                {[...Array(5)].map((_, index) => (
                  <div
                    className={`table-row ${isSelected(index) ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleRowClick(index)}
                  >
                    <input
                      type="checkbox"
                      checked={isSelected(index)}
                      onChange={() => handleCheckboxChange(index)} 
                    />
                    <div>INV05-202</div>
                    <div>LAGO LTD</div>
                    <div>10-06-2024</div>
                    <div>Emili Scott</div>
                    <div>£1,444.20</div>
                  </div>
            ))}
                  
               
              </div>
              <div className="pagination">
                <span>Export Options</span>
                <div>
                        <img src={left} className="pagination-arrow"/>
                        <h1>Previous</h1>
                        <span>1</span>
                        <h1>Next</h1>
                        <img src={right} className="pagination-arrow"/>
                </div>
              </div>
            </div>
            {isPayNowClicked && (
              <div className="pay-now-content">
                <PayNow onClose={() => setIsPayNowClicked(false)} />
              </div>
            )}
            {isRescheduleClicked && (
              <div className="reschedule-content">
                <Reschedule onClose={() => setIsRescheduleClicked(false)} />
              </div>
            )}
            {isRevokeClicked && (
              <div className="pay-now-content">
                <Revoke onClose={() => setIsRevokeClicked(false)} />
              </div>
            )}
            {isHoldClicked && (
              <div className="pay-now-content">
                <Hold onClose={() => setIsHoldClicked(false)} />
              </div>
            )}
          </div>
        ) : isViewUncategorizedExpenses && selectedBankAccount ? (
          <div className={`expenses-view ${isCategorizeClicked ? 'categorize-active' : ''}`}>
                <div className="expenses-header">
                  <div className="header-top">
                    <div className="bank-info">
                      <img src={`data:image/png;base64,${selectedBankAccount.logo}`} alt={selectedBankAccount.name} className="bank-logo" />
                      <div className="bank-details">
                        <h2>{selectedBankAccount.name}</h2>
                        <p>xxxxxxxxxxx2244</p>
                        <p>Connection ID: {selectedBankAccount.connectionId}</p>
                      </div>
                    </div>
                    <div className="total-data">
                      <div className='total-debits'>
                        <h6>Total uncategorized debits</h6>
                        <h3>£1,440.00</h3>
                      </div>
                      <div className='total-credits'>
                        <h6>Total uncategorized credits</h6>
                        <h3>£1,440.00</h3>
                      </div>
                    </div>
                  </div>
        
                  <div className="filter">
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    <select className="expenses-filter">
                      <option>All Transactions</option>
                      <option>Loans</option>
                      <option>Deposits</option>
                      <option>Withdrawals</option>
                    </select>
                  </div>
                </div>

                <div className="transaction-table">
                  <div className="table-header">
                    <div>Date & Time</div>
                    <div>UTR</div>
                    <div>Transaction Description</div>
                    {/* <div>Category</div> */}
                    <div>Amount</div>
                    {/* <div></div> */}
                  </div>
                  <div className="table-body">
                    {transactions.map((transaction, index) => (
                      <div className="table-row" key={index}>
                        <div>{transaction.date}</div>
                        <div>{transaction.utr}</div>
                        <div>{transaction.description}</div>
                        {/* <div>{transaction.category}</div> */}
                        <div className={`amount ${transaction.amount < 0 ? 'negative' : 'positive'}`}>
                          {transaction.amount < 0 ? '-' : '+'}£{Math.abs(transaction.amount).toFixed(2)}
                        </div>
                        <img src={categorizeIcon} onClick={() => setIsCategorizeClicked(true)}/>
                      </div>
                    ))}
                  </div>
                  <div className="pagination">
                    <span>Export Options</span>
                    <div>
                      <img src={left} className="pagination-arrow"/>
                      <h1>Previous</h1>
                      <span>1</span>
                      <h1>Next</h1>
                      <img src={right} className="pagination-arrow"/>
                    </div>
                  </div>
                </div>
                {isCategorizeClicked && (
              <div className="pay-now-content">
                <Categorize onClose={closeModal} />
              </div>
            )}
          </div>
            ) : (
          <>
            <div className="header">
              <div className="header-left"></div>
              <div className="header-right">
                <button className="header-button" >
                  <img src={addNewIcon} alt="Add New" />
                  Connect New Bank Account
                </button>
              </div>
            </div>
            <div className="bankAccount-table">
              <div className="table-header">
                <div>Banks</div>
                <div>Connection ID</div>
                <div>Account Balance</div>
                <div>One click payments</div>
              </div>
              <div className='table-row-wrapper'>
                {bankAccounts.map((bankAccount, index) => (
                  <div className="table-row" key={bankAccount.id}>
                    <div className="bankAccount-info">
                      <img src={`data:image/png;base64,${bankAccount.logo}`}  className="bankAccount-logo" />
                      <div className='account-info'>
                        <span>{bankAccount.name}</span>
                        <p>xxxxxxxxxxx2244</p>
                      </div>
                    </div>
                    <div>{bankAccount.connectionId}</div>
                    <div className="accountBalance">{bankAccount.accountBalance}</div>
                    <div className="sync">
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                      <span>{bankAccount.lastSynced ? bankAccount.lastSynced : "last synced on 09/22/20"}</span>
                      <img src={syncIcon} alt="Sync" className="sync-icon" />
                      <img src={moreIcon} alt="more" className="more-icon" onClick={togglePopup} />
                      {isMorePopupOpen && (
                        <div 
                          className="popup-menu" 
                          style={{ top: popupPosition.top + 30, left: popupPosition.left - 190 }}
                        >
                          <div className="popup-item" onClick={() => toggleRemovePopup(bankAccount)} >Remove/ Disconnect</div>
                          <div className="popup-item" onClick={() => toggleViewTransactionPopup(bankAccount) }>View Transactions</div>
                          <div className="popup-item" onClick={() => toggleViewUpcomingPayments(bankAccount)}>View Upcoming Payments</div>
                          <div className="popup-item" onClick={() => toggleViewUncategorizedExpenses(bankAccount)}>Uncategorized Expenses</div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {isRemovePopupOpen && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Remove Bank Account?</h2>
                  <p>Confirming this will remove all the transactions and associated<br /> data from that account.<br /><span>This action can be undone!</span></p>
                  <div className="modal-actions">
                    <div className="proceed-button">
                      <img src={trashIcon} className="trash-icon"/>
                      <div   onClick={handleRemoveBankAccount}>Yes, Remove</div>
                    </div>
                    <button type="button" className="cancel-button" onClick={() => setIsRemovePopupOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
              </div>
            </div>
          </>
      )}
    </div>
  );
};

export default BankAccount;