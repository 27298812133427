import React, { useEffect, useState } from 'react';
import './styles.scss';
import addUserIcon from "../../assets/icons/admin/add-user.png";
import removeUserIcon from "../../assets/icons/admin/remove-user.png";
import refreshIcon from "../../assets/icons/admin/refresh.png";
import searchIcon from "../../assets/icons/admin/search.png";
import listViewIcon from "../../assets/icons/admin/list-view.png";
import gridViewIcon from "../../assets/icons/admin/grid-view.png";
import moreIcon from "../../assets/icons/admin/more.png";
import successIcon from "../../assets/icons/admin/success.png"; // Add success icon
import Exported from '../../services/endpoints';
import { request } from '../../services';
import { useSelector } from 'react-redux';

const usersData = [
  { name: 'Emili Scott', email: 'emili@abccompany.com', title: 'COO (Owner)', rights: 'Administrator', phone: '+44 20 7123 4567', status: 'Active' },
  { name: 'Jac Zeriac', email: 'jac@abccompany.com', title: 'CEO', rights: 'Administrator', phone: '+44 20 7123 4568', status: 'Active' },
  { name: 'Sarah Martin', email: 'sarah@abccompany.com', title: 'Accountant', rights: '3 Rights activated', phone: '+44 20 4321 9876', status: 'Invited' },
  { name: 'Alan Ferno', email: 'alan@abccompany.com', title: 'Jr. Associate', rights: '2 Rights activated', phone: '+44 22 1234 0987', status: 'Inactive' }
];

const UserScreen = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState("list");
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const orgId = useSelector((state)=> state.auth.orgId);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [userData, setUserData] = useState([]);


  
  const userDetails = async () => {
    try {
      const res = await request({
        url: `organization/getUsersList/${orgId}`,
        method: Exported.APIMethods.GET,
      });
      return res;
    } catch (err) {
      console.error("Error fetching organization details", err);
      return null;
    }
  };

  const addUser = async () => {
    try {
      const res = await request({
        url: `organization/addUser/${orgId}`,
        method: Exported.APIMethods.POST,
        data: {
            firstName,
            lastName,
            email,
            authorities: [title],
        }
      });
      return res;
    } catch (err) {
      console.error("Error fetching organization details", err);
      return null;
    }
  };

  useEffect(()=>{
     userDetails()
      .then((res)=> {
        setUserData(res.data);
        setUsers(res.data);
    })
  },[orgId])

  
 


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === "") {
      setUsers(userData);
    } else {
      setUsers(userData.filter(user => 
        user.firstName?.toLowerCase().includes(event.target.value.toLowerCase()) || 
        user.lastName?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        user.email?.toLowerCase().includes(event.target.value.toLowerCase())
      ));
    }
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === "list" ? "grid" : "list");
  };

  const handleAddUser = () => {
    setIsModalOpen(true);
  };

  const handleProceed = async () => {
    let res = await addUser();
    console.log("res:", res);
    setIsModalOpen(false);
    if(res.data.success){
        setIsPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const togglePopup = (event) => {
    const { top, left } = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top, left });
    setIsMorePopupOpen(!isMorePopupOpen);
  };

  return (
    <div className="user-screen">
      <div className="header">
        <div className="header-left">
        </div>
        <div className="header-right">
          <button className="header-button" onClick={handleAddUser}>
            <img src={addUserIcon} alt="Add User" />
            Add User
          </button>
          <div className="search-box">
            <img src={searchIcon} alt="Search" />
            <input 
              type="text" 
              placeholder="Search active users" 
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className={`user-table ${viewMode}`}>
        <div className="table-header">
          <div>Name</div>
          <div>E-mail</div>
          <div>Title</div>
          <div>Rights</div>
          <div>Phone</div>
          <div>Status</div>
        </div>
        <div className='table-row-wrapper'>
        {users.map((user, index) => (
          <div className="table-row" key={index}>
            <div className="user-info">
            {user.firstName}&nbsp;{user.lastName}
            </div>
            <div>{user.email}</div>
            {user.title ? <div>{user.title}</div> : <div>Admin </div>}
            {user.rights ? <div className="rights">{user.rights}</div> : <div>Admin </div>}
            {user.mobileNumber ? <div>{user.mobileNumber}</div> : <div> mobile number </div>}
            <div className={`status ${user.status.toLowerCase()}`}>{user.status.toLowerCase()}</div>
            <div>
                <img src={moreIcon} alt="More" className="more-icon"  onClick={togglePopup}/>
                {isMorePopupOpen && (
                <div 
                  className="popup-menu" 
                  style={{ top: popupPosition.top + 30, left: popupPosition.left - 190 }}
                >
                  <div className="popup-item">Manage Rights</div>
                  <div className="popup-item">Remove User</div>
                  <div className="popup-item">Mark as Inactive</div>
                </div>
              )}
            </div>
          </div>
        ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add User</h2>
            <form>
              <div className='modal-data'>
                <div className='modal-name'>
                  <div className='first-name'>
                    <label>First Name</label>
                    <input onChange={(e)=> setFirstName(e.target.value)} type="text" />
                  </div>
                  <div className='last-name'>
                    <label>Last Name</label>
                    <input onChange={(e)=> setLastName(e.target.value)} type="text" />
                  </div>
                </div>
                <div className='modal-email'>
                  <label>E-Mail</label>
                  <input onChange={(e)=> setEmail(e.target.value)} type="email" />
                </div>
                <div className='modal-title'>
                  <label>Title</label>
                  <select onChange={(e) => setTitle(e.target.value)}>
                    <option value="">Select Title</option>
                    <option value="CEO">CEO</option>
                    <option value="COO">COO</option>
                    <option value="CFO">CFO</option>
                    <option value="Finance Director">Finance Director</option>
                    <option value="Financial Controller">Financial Controller</option>
                    <option value="Sr. Manager">Sr. Manager</option>
                    <option value="Manager">Manager</option>
                    <option value="Sr. Associate">Sr. Associate</option>
                    <option value="Associate">Associate</option>
                    <option value="Accountant">Accountant</option>
                    <option value="Others">Others</option>
              </select>
                </div>
              </div>
              <div className="modal-actions">
                <button type="button" className="proceed-button" onClick={handleProceed}>Proceed</button>
                <button type="button" className="cancel-button" onClick={() => setIsModalOpen(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="modal">
          <div className="modal-content popup">
            <div>
                <img src={successIcon} alt="Success" className="success-icon" /> 
            </div>
            <h2>Invitation sent successfully!</h2>
            <p>User can join your organization upon accepting the invitation. You can activate the rights on "User Management" page.
               (Please ensure to check on spam folder too for the invitation).
            </p>
            <button type="button" className="close-button" onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserScreen;
