
import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

// Import the images
import BgElement1 from '../../assets/images/BG Element 1.png';
import BgElement2 from '../../assets/images/BG Element 2.png';
import BgElement3 from '../../assets/images/BG Element 3.png';

//Import icons
import group1 from "../../assets/icons/Group 1.png";
import group2 from "../../assets/icons/Group 2.png";
import arrow from "../../assets/icons/rightArrow.png";


const Signup = () => {
  const navigate = useNavigate();

  return (
    <div className="signup-screen">
      <div className="content-wrapper">
        <h1>Welcome to PennyFlo!</h1>
        <p>Where you can manage and control all your cash</p>
        
        <div className="options">
          <div className="option" onClick={() => navigate('/authSignup/create-business-account')}>
            
              {/* You can replace this with an actual icon component */}
              <img src={group1} width={80} />
            
            <div className="option-content">
              <h2>Create a business account</h2>
              <p>Register your company on PennyFlo</p>
            </div>
            <img className='option-arrow' src={arrow} width={20}/>
          </div>

          <div className="option" onClick={() => navigate('/authSignup/join-company')}>
            
              {/* You can replace this with an actual icon component */}
              <img src={group2} width={80} />
            
            <div className="option-content">
              <h2>Join a company</h2>
              <p>Join a company on invitation</p>
            </div>
            <img className='option-arrow' src={arrow} width={20}/>
          </div>
        </div>
        
        <footer className="footer">
          <p className="footer-company">PennyFlo Limited • United Kingdom</p>
          <div className="footer-links">
            <p>Privacy Policy</p>
            <p>Terms of Use</p>
          </div>
        </footer>
      </div>
      
      {/* Background elements */}
      <img src={BgElement1} alt="Background Element 1" className="bg-element bg-element-1" />
      <img src={BgElement2} alt="Background Element 2" className="bg-element bg-element-2" />
      <img src={BgElement3} alt="Background Element 3" className="bg-element bg-element-3" />
    </div>
  );
};

export default Signup;
