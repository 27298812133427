import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes";
import React, { useRef } from "react";
import { toCamelCase } from "../utils";
import Loader from "../components/loader";
import ChatModal from "../screens/chatModal";
import "../index.css";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import PrivateRoute from "./privateRoute";

const AppRoutes = () => {
  const loaderRef = useRef(null);
  const token = useSelector((state) => state.auth.accessToken);

  const getReactElement = (route) => {
    let screen = toCamelCase(route);
    console.log("screen:", screen);
    return React.createElement(require(`../screens/${screen}`).default);
  };

  const getLayout = (route, screen) => {
    let layout = toCamelCase(route);
    const LayoutComponent = require(`../layouts/${layout}`).default;

    return <LayoutComponent>{getReactElement(screen)}</LayoutComponent>;
  };

  return (
    <div>
      <Router>
        <Routes>
          {routes.map(({ layout = "", path, childrens, component = "", init = "" }, index) => {
            if (childrens) {
              return childrens.map(({ screen, childPath }, i) => {
                return (
                  <Route
                    key={`${index}_${i}`}
                    path={`${path}${childPath}`}
                    element={
                      <PrivateRoute path={path} redirectTo="/main/dashboard">
                        {getLayout(layout, screen)}
                      </PrivateRoute>
                    }
                  />
                );
              });
            } else {
              return path === "*" ? (
                <Route key={"page_404"} path={path} element={getReactElement(component)} />
              ) : (
                <Route key={"init"} path={path} element={<Navigate to={init} />} />
              );
            }
          })}
          <Route path="/chat" element={<ChatModal />} />
          <Route path="/auth/login" element={token ? <Navigate to="/main/dashboard" /> : getReactElement("login")} />
        </Routes>
      </Router>
      <NotificationContainer />
      <Loader ref={loaderRef} />
    </div>
  );
};

export default AppRoutes;
