import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import call from "../../assets/icons/admin/call-calling.png";
import global from "../../assets/icons/admin/global.png";
import location from "../../assets/icons/admin/location.png";
import profile from "../../assets/icons/admin/profile-circle.png";
import sms from "../../assets/icons/admin/sms-tracking.png";
import verified from "../../assets/icons/admin/Verified.png";
import editIcon from "../../assets/icons/admin/edit.png"; // Editable icon
import { request } from '../../services';
import Exported from '../../services/endpoints';
import { useSelector } from 'react-redux';


const OrganizationDetails = () => {
  const orgId = useSelector((state)=> state.auth.orgId);
  const [data, setData] = useState({});
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [isEditingContact, setIsEditingContact] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingWebsite, setIsEditingWebsite] = useState(false);
  const navigate = useNavigate();

  const orgDetails = async () => {
    try {
      const res = await request({
        url: "organization",
        method: Exported.APIMethods.GET,
        params: { id: orgId },
      });
      return res;
    } catch (err) {
      console.error("Error fetching organization details", err);
      return null;
    }
  };  


 useEffect(() => {
    orgDetails().then((res) => {
      if (res && res.data && res.data.organization) {
        setData(res.data.organization);
        setContactNumber(res.data.organization.contactNumber || "");
        setEmail(res.data.organization.email || "");
        setWebsite(res.data.organization.website || "");
      }
    });
  }, [orgId]);

  const handleUpdate = async () => {
    try {
      const res = await request({
        url: "organization",
        method: Exported.APIMethods.PUT,
        data: { 
             organizationId : orgId,
             contactNumber : contactNumber,
             email : email,
             website : website,
        },
      });
      if (res.data.success) {
        setData({ ...data, contactNumber, email, website });
      }
    } catch (err) {
      console.error("Error updating organization details", err);
    }
  };

  const handleBlur = async () => {
    setIsEditingContact(false);
    setIsEditingEmail(false);
    setIsEditingWebsite(false);
    handleUpdate();
  };

  return (
    <div className="organization-details-screen">
      <div className="content-wrapper">
        <div className="organization-header">
          <h1>{data.legalName} <img src={verified} className="verified-icon"/></h1>
          <p>app.pennyflo.io/pennyflo/abccompanylimited</p>
        </div>
        <div className="organization-body">
          <div className="organization-left">
            <div className="contact-info">
              <div className="info-item">
                <img src={call} className="icon"/>
                {isEditingContact ? (
                  <input
                    type="text"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  <p>{contactNumber ? contactNumber : "Contact Number"} <img src={editIcon} className="edit-icon" onClick={() => setIsEditingContact(true)} /></p>
                )}
              </div>
              <div className="info-item">
                <img src={sms} className="icon"/>
                {isEditingEmail ? (
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  <p>{email ? email : "Email"} <img src={editIcon} className="edit-icon" onClick={() => setIsEditingEmail(true)} /></p>
                )}
              </div>
              <div className="info-item">
                <img src={global} className="icon"/>
                {isEditingWebsite ? (
                  <input
                    type="text"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  <p>{website ? website : "Website"} <img src={editIcon} className="edit-icon" onClick={() => setIsEditingWebsite(true)} /></p>
                )}
              </div>
              <div className="info-item">
                <img src={location} className="icon"/>
                <p>{data.address?.locality} <br/> {data.address?.premises} <br/>
                   {data.address?.addressLine1} <br/> {data.address?.postalCode}
                </p>
              </div>
            </div>
          </div>
          <div className="organization-right">
            <div className="details-column">
              <div className="details-item">
                <p className="details-label">Country of Incorporation</p>
                <p className="details-value">{data.address?.country}</p>
              </div>
              <div className="details-item">
                <p className="details-label">Business Legal Name</p>
                <p className="details-value">{data.legalName}</p>
              </div>
              <div className="details-item">
                <p className="details-label">Companies House Registration Number</p>
                <p className="details-value">{data.companyHouseNumber}</p>
              </div>
              <div className="details-item">
                <p className="details-label">Date of Incorporation</p>
                <p className="details-value">{data.dateOfCreation}</p>
              </div>
              <div className="details-item">
                <p className="details-label">Business Type</p>
                <p className="details-value">{data.type}</p>
              </div>
            </div>
            <div className="details-column">
              <div className="details-item">
                <p className="details-label">Incorporation Certificate</p>
                <a href="#" className="details-link">View</a>
              </div>
              <div className="details-item">
                <p className="details-label">VAT Registration Certificate</p>
                <a href="#" className="details-link">View</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetails;
