import React from 'react';
import './styles.scss';
import infocircle from '../../assets/icons/info-circle.png'

const JoinCompany = () => {
  return (
    <div className="join-screen">
        <div className='info-circle'>
            <img src={infocircle}/>
        </div>
        <div className='message-content'>
          <div className='highlight'>
            Thanks for your interest
          </div>
          <div className='message'>
            Contact your administrator to join the organization in PennyFlo.
          </div>
        </div>
    </div>
  );
};

export default JoinCompany;