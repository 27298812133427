import React from "react";
import "./statement.scss"; // Assume styles
import bgImage from "../../assets/images/background.png"; // Assume background image
import logoIcon from "../../assets/images/profile-image.png"; // Assume logo icon

const StatementOfAccounts = () => {
  const statementData = [
    {
      date: "01-04-2024",
      transaction: "Opening Balance",
      amount: "£0.00",
      payments: "£0.00",
      balance: "£0.00",
      details: "",
    },
    {
      date: "03-04-2024",
      transaction: "Bill",
      amount: "£1030.00",
      payments: "",
      balance: "£1030.00",
      details: "INV24-006 (due on 10-04-2024)",
    },
    {
      date: "03-04-2024",
      transaction: "Tax Withheld",
      amount: "£206.00",
      payments: "",
      balance: "£824.00",
      details: "INV24-006",
    },
    {
      date: "03-04-2024",
      transaction: "Payment Made",
      amount: "",
      payments: "£824.00",
      balance: "£0.00",
      details: "For payment of INV24-006",
    },
    // Add more entries as necessary...
  ];

  return (
    <div className="statement-container">
      <div className="statement-header" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="header-left">
          <img src={logoIcon} alt="Company Logo" className="company-logo" />
          <h2>Statement of Accounts</h2>
          <p style={{ top: "100px", left: "135px" }}>FROM: 01/04/2024</p>
          <p style={{ top: "125px", left: "135px" }}>TO: 04/04/2024</p>
        </div>
        <div className="header-right">
          <h3>To,</h3>
          <p>LAGO LTD</p>
          <p>Vendor ID: LL012-090424-13</p>
          <p>128 City Road, London, United Kingdom</p>
          <p>VAT: GB999 9999 73</p>
        </div>
        <div className="account-summary">
          <h3>Account Summary</h3>
          <p>Billed Amount: £35,444.54</p>
          <p>Amount Paid: £32,000.00</p>
          <p>Balance Due: £3,444.54</p>
        </div>
      </div>

      <div className="statement-body">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Transactions</th>
              <th>Amount</th>
              <th>Payments</th>
              <th>Balance</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {statementData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.date}</td>
                <td>{entry.transaction}</td>
                <td>{entry.amount}</td>
                <td>{entry.payments}</td>
                <td>{entry.balance}</td>
                <td>{entry.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="statement-footer">
        <div className="tax-paid">
          <p>Tax Paid:</p>
          <p>£532.35</p>
        </div>
        <div className="balance-due">
          <p>Balance Due:</p>
          <p>£0.00</p>
        </div>
      </div>
    </div>
  );
};

export default StatementOfAccounts;
