// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}
.main-layout .tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
}
.main-layout .tabs .tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: grey;
  transition: border-bottom 0.3s;
}
.main-layout .tabs .tab:hover {
  border-bottom: 2px solid #6c63ff;
  color: #6c63ff;
}
.main-layout .tabs .tab.active {
  border-bottom: 2px solid #6c63ff;
  font-weight: bold;
  color: #6c63ff;
}
.main-layout .content-wrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/admin/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,6BAAA;AACR;AACQ;EACI,oBAAA;EACA,eAAA;EACA,WAAA;EACA,8BAAA;AACZ;AACY;EACI,gCAAA;EACA,cAAA;AAChB;AAEY;EACI,gCAAA;EACA,iBAAA;EACA,cAAA;AAAhB;AAKI;EACI,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AAHR","sourcesContent":[".main-layout {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2rem;\n    background-color: #f5f5f5;\n    min-height: 100vh;\n\n    .tabs {\n        display: flex;\n        justify-content: space-between;\n        border-bottom: 2px solid #ddd;\n\n        .tab {\n            padding: 0.5rem 1rem;\n            cursor: pointer;\n            color: grey;\n            transition: border-bottom 0.3s;\n\n            &:hover {\n                border-bottom: 2px solid #6c63ff;\n                color: #6c63ff;\n            }\n\n            &.active {\n                border-bottom: 2px solid #6c63ff;\n                font-weight: bold;\n                color: #6c63ff;\n            }\n        }\n    }\n\n    .content-wrapper {\n        width: 100%;\n        background-color: #fff;\n        border-radius: 10px;\n        width: 100%;\n        padding: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
