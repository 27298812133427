import './styles.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const SuccessMessage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/auth/login');
    }, 5000); 
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="success-screen">
      <div className="success-message">
        <span className="highlight">Congratulations!</span> You’ve successfully completed registering <br /> your profile with PennyFlo.
      </div>
      <div className='note'>Verification successful! Redirecting you to Login page in next 5 seconds</div>
    </div>
  );
};

export default SuccessMessage;