import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import bg from "../../assets/images/background.png"; // Assume background image
import profileImage from "../../assets/images/profile.png"; // Assume profile image
import emailIcon from "../../assets/icons/email.png"; // Assume email icon
import editIcon from "../../assets/icons/edit.png";
import phoneIcon from "../../assets/icons/mobile.png"; // Assume phone icon
import trashIcon from "../../assets/icons/trash.png"; // Assume trash icon
import close from "../../assets/icons/close-grey.png";
import info from "../../assets/icons/info-circle-blur.png";
import eye from "../../assets/icons/eye.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveUserId } from "../../redux/reducers/authSlice";
import { savePassword } from "../../redux/reducers/authSignupSlice";
import Exported from "../../services/endpoints";
import { request } from "../../services";
import { Element, Link } from "react-scroll";
import { Cropper } from "react-advanced-cropper";
// import 'react-advanced-cropper/dist/style.css';

const CompleteProfile = () => {
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [notifications, setNotifications] = useState({
    signinAlert: true,
    thirdPartyAlert: true,
    newsletterAlert: true,
  });
  const [isChangePasswordModal, setIsChangePasswordModal] = useState(false);
  const [isMobileNumberModal, setIsMobileNumberModal] = useState(false);
  const [activeTab, setActiveTab] = useState("personal-info");
  const [selectedImage, setSelectedImage] = useState(profileImage);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);
  const [isCropping, setIsCropping] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  // const userId = 54;

  const handleToggle = () => {
    const newMfaState = !isMfaEnabled;
    setIsMfaEnabled(newMfaState);
    toggleMfa(newMfaState);
  };

  const toggleMfa = async (enableMfa) => {
    try {
      const res = await request({
        url: "/auth/switchMfa",
        method: "Exported.APIMethods.POST",
        params: { enableMfa },
      });
      console.log("toggleMfa response:", res);
      if (res && res.success) {
        setIsMfaEnabled(enableMfa);
      }
      return res;
    } catch (err) {
      console.error("Error toggling MFA", err);
      return null;
    }
  };

  const openChangePasswordModal = () => {
    setIsChangePasswordModal(true);
  };
  const closeChangePasswordModal = () => {
    setIsChangePasswordModal(false);
  };

  const openMobileNumberModal = () => {
    setIsMobileNumberModal(true);
  };

  const closeMobileNumberModal = () => {
    setIsMobileNumberModal(false);
  };

  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberDisp, setMobileNumberDisp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [mobileOtpReference, setMobileOtpReference] = useState("");
  const [otpReference, setOtpReference] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    capital: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleTooltip = () => {
    setShowTooltip((prevState) => !prevState);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const capital = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidations({
      length,
      number,
      capital,
      specialChar,
    });

    return length && number && capital && specialChar;
  };

  const handleOtpChange = (e) => {
    const newOtp = e.target.value;
    setOtp(newOtp);
    validatePassword(newOtp);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    if (!isTyping) {
      setIsTyping(true);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const sendPasswordOtp = async () => {
    try {
      const res = await request({
        url: "auth/requestChangePasswordOtp",
        method: Exported.APIMethods.POST,
      });
      console.log("sendOtp response:", res);
      setOtpReference(res?.data?.referenceNumber);
      return res;
    } catch (err) {
      console.error("Error sending OTP", err);
      return null;
    }
  };
  const verifyPasswordOtp = async () => {
    try {
      const res = await request({
        url: "auth/confirmPasswordChange",
        method: Exported.APIMethods.POST,
        data: { newPassword: password, otpReference, otp },
      });
      console.log("verifyOtp response:", res);
      return res;
    } catch (err) {
      console.error("Error verifying OTP", err);
      return null;
    }
  };
  const handleSendPasswordOtpClick = async (e) => {
    e.preventDefault();
    await sendPasswordOtp();
  };
  const handleVerifyPasswordOtpClick = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!validatePassword(password)) {
      alert("Password does not meet the requirements");
      return;
    }

    await verifyPasswordOtp();
  };

  const handleMobileNumberChange = (e) => {
    const newMobileNumber = e.target.value;
    setMobileNumber(newMobileNumber);
  };
  const handleMobileOtpChange = (e) => {
    const newMobileOtp = e.target.value;
    setMobileOtp(newMobileOtp);
  };

  const sendOtp = async (mobileNumber) => {
    try {
      console.log({ mobileNumber });
      console.log(typeof mobileNumber);
      const res = await request({
        url: "user/changeMobile",
        method: Exported.APIMethods.POST,
        params: { mobileNumber },
      });
      console.log("otp response:", res);
      setMobileOtpReference(res?.data?.referenceNumber);
      return res;
    } catch (err) {
      console.error("Error checking mobile number existence", err);
      return null;
    }
  };

  const verifyOtp = async (mobileOtpReference, mobileOtp) => {
    try {
      console.log(mobileOtpReference, mobileOtp);
      const res = await request({
        url: "user/verifyChangeMobile",
        method: Exported.APIMethods.POST,
        data: { otpReference: mobileOtpReference, otp: mobileOtp },
      });
      console.log("verifyOtp response:", res);
      return res;
    } catch (err) {
      console.error("Error verifying mobile otp", err);
      return null;
    }
  };
  const handleSendOtpClick = async (e) => {
    e.preventDefault();
    if (mobileNumber) {
      await sendOtp(mobileNumber);
    } else {
      console.error("Mobile number is required");
    }
  };

  const handleVerifyOtpClick = async (e) => {
    e.preventDefault();
    if (mobileOtpReference && mobileOtp) {
      await verifyOtp(mobileOtpReference, mobileOtp);
      setMobileNumberDisp(mobileNumber);
      setIsMobileNumberModal(false);
    } else {
      console.error("OTP reference and OTP are required");
    }
  };

  useEffect(() => {
    const profileData = async () => {
      try {
        const res = await request({
          url: "user/profile",
          method: Exported.APIMethods.GET,
        });
        console.log("profile response:", res);
        setMobileNumberDisp(res?.data?.mobileNumber);
        return res;
      } catch (err) {
        console.error("Error verifying mobile otp", err);
        return null;
      }
    };
    profileData();
  }, []);

  const getProfile = () => {
    return new Promise((resolve, reject) => {
      request({
        url: "user/profile",
        method: Exported.APIMethods.GET,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleImageUpload = (file) => {
    const formData = new FormData();
    formData.append("photo", file);
    uploadImage(formData).then((res) => {
      setSelectedImage(URL.createObjectURL(file));
      console.log("Image uploaded:", res);
    });
  };

  const uploadImage = (formData) => {
    return new Promise((resolve, reject) => {
      request({
        url: "user/uploadPhoto",
        method: Exported.APIMethods.POST,
        data: formData,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    getProfile().then((res) => setProfileData(res.data));
  }, []);

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setIsCropping(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], "cropped-image.png", {
          type: "image/png",
        });
        handleImageUpload(croppedFile);
        setIsCropping(false);
      });
    }
  };

  const handleEditClick = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    const downloadPhoto = async () => {
      try {
        const res = await request({
          url: "user/downloadPhoto",
          method: Exported.APIMethods.GET,
          params: { userId },
        });
        console.log("downloading response:", res?.data);
        const images = res?.data?.image;
        if (images) {
          setSelectedImage(images);
        }
        return res;
      } catch (err) {
        console.error("Error Downloading photo", err);
        return null;
      }
    };
    downloadPhoto();
  }, [userId]);

  return (
    <div className="complete-profile-container">
      <div className="profile-header" style={{ backgroundImage: `url(${bg})` }}>
        <h2>Complete Profile</h2>
        <p>Employee ID: 60027-0234-001</p>
      </div>
      <div className="tab-wrapper">
        <div className="profile-tabs">
          <div className="profile-image-wrapper">
            <img src={`data:image/png;base64,${selectedImage}`} alt="Profile" className="profile-image" />
            <div className="edit-overlay">
              <img onClick={handleEditClick} src={editIcon} alt="Edit" className="edit-icon" />
            </div>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
          </div>

          {isCropping && image && (
            <div className="crop-tool">
              <Cropper
                src={image}
                ref={cropperRef}
                className="cropper"
                stencilProps={{
                  aspectRatio: 1,
                  width: 148,
                  height: 148,
                  resizable: false,
                  movable: false,
                  borderRadius: 15,
                }}
                imageRestriction="stencil"
              />
              <button className="crop-button" onClick={handleCrop}>
                Done
              </button>
            </div>
          )}

          {croppedImage && (
            <div>
              <h3>Cropped Image Preview:</h3>
              <img
                src={croppedImage}
                alt="Cropped Preview"
                className="cropped-image-preview"
                style={{
                  width: "148px",
                  height: "148px",
                  borderRadius: "15px",
                }}
              />
            </div>
          )}
          <Link
            className={`tab ${activeTab === "personal-info" ? "active" : ""}`}
            to="personal-info"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("personal-info")}
          >
            Personal info
          </Link>
          <Link
            className={`tab ${activeTab === "email-addresses" ? "active" : ""}`}
            to="email-addresses"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("email-addresses")}
          >
            E-mail address
          </Link>
          <Link
            className={`tab ${activeTab === "mobile-numbers" ? "active" : ""}`}
            to="mobile-numbers"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("mobile-numbers")}
          >
            Mobile number
          </Link>
          <Link
            className={`tab ${activeTab === "sign-ins" ? "active" : ""}`}
            to="sign-ins"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("sign-ins")}
          >
            Sign-ins
          </Link>
          <Link
            className={`tab ${activeTab === "password" ? "active" : ""}`}
            to="password"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("password")}
          >
            Password
          </Link>
          <Link
            className={`tab ${activeTab === "mfa" ? "active" : ""}`}
            to="mfa"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("mfa")}
          >
            MFA
          </Link>
          <Link
            className={`tab ${activeTab === "email-notifications" ? "active" : ""}`}
            to="email-notifications"
            smooth={true}
            duration={500}
            onClick={() => handleSetActiveTab("email-notifications")}
          >
            Email-notifications
          </Link>
        </div>
      </div>

      <div className="profile-content">
        <Element name="personal-info" className="section personal-info">
          <h3>Personal Information</h3>
          <div className="info-group">
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Full Name</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>Emili Scott</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Name as per Document</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>Emili Scott</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Role</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>CEO</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Gender</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>Male</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Country</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>United Kingdom</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>State</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>London</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Language</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>English</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Mobile Number</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>{mobileNumberDisp}</p>
            </div>
            <div>
              <p style={{ color: "#848484", fontSize: "12px" }}>Last login</p>
              <p style={{ color: "#434343", fontSize: "14px" }}>Monday, 8 July 2024, 4:20pm</p>
            </div>
          </div>
        </Element>
        <Element name="email-addresses" className="section email-addresses">
          <div className="section-header">
            <h3>My e-mail addresses</h3>
            <img src={editIcon} alt="Edit" className="edit-icon" />
          </div>
          <p className="section-description">
            You can use the following email address to sign into your account and also to reset your password if you
            ever forget it.
          </p>
          <div className="email-item">
            <img src={emailIcon} alt="Email" />
            <div className="email-details">
              <div className="email-info">
                <p className="email-address">emili@abccompany.com</p>
                <span className="primary-label">Primary</span>
              </div>
              <p className="email-added-date">2 months ago</p>
            </div>
          </div>
        </Element>
        <Element name="mobile-numbers" className="section mobile-numbers">
          <div className="section-header">
            <h3>My mobile numbers</h3>
            <img src={editIcon} alt="Edit" className="edit-icon" onClick={openMobileNumberModal} />
          </div>
          <p className="section-description">View and manage all of the mobile numbers associated with your account.</p>
          <div className="phone-item">
            <img src={phoneIcon} alt="Phone" />
            <div className="phone-details">
              <div className="phone-info">
                <p className="phone-number">{mobileNumberDisp}</p>
                <span className="primary-label">Primary</span>
              </div>
              <p className="phone-added-date">2 months ago</p>
            </div>
          </div>
        </Element>
        <Element name="sign-ins" className="section device-signins">
          <div className="section-header">
            <h3>Device Sign-ins</h3>
            <button className="delete-all-button">Delete from all locations</button>
          </div>
          <p className="section-description">
            View and manage the list of locations where you’ve signed in on your devices.
          </p>
          <div className="device-item">
            <p className="device-name">Desktop</p>
            <p className="device-location">London, United Kingdom</p>
            <span className="status-label">Active</span>
            <img src={trashIcon} alt="Delete" className="trash-icon" />
          </div>
          <div className="device-item">
            <p className="device-name">Mac</p>
            <p className="device-location">Kochi, India</p>
            <span className="status-label">Active</span>
            <img src={trashIcon} alt="Delete" className="trash-icon" />
          </div>
        </Element>
        <Element name="password" className="section password">
          <div>
            <h3>Password</h3>
            <p>Last changed 6 months ago</p>
          </div>
          <button className="change-password" onClick={openChangePasswordModal}>
            Change password
          </button>
        </Element>
        <Element name="mfa" className="section mfa">
          <div>
            <h3>Multi-Factor Authentication</h3>
            <p>Add multi-factor authentication (MFA) to add an extra layer of security to your account</p>
          </div>
          <div className={`slider ${isMfaEnabled ? "active" : ""}`} onClick={handleToggle}></div>
        </Element>
        <Element name="email-notifications" className="section email-notifications">
          <h2>Email Notifications</h2>
          <div className="notification-item">
            <div>
              <span>New Sign-in to account alert</span>
              <p>Receive email alerts whenever your account is signed in from a new device, browser, or location.</p>
            </div>
            <div className="toggle-switch">
              <input type="checkbox" id="signin-alert" />
              <label htmlFor="signin-alert"></label>
            </div>
          </div>
          <div className="notification-item">
            <div>
              <span>Third-party app access alert</span>
              <p>
                Receive email alerts whenever your account is accessed from a new third party or location. <br />
                Example: IMAP/POP clients such as mail apps.
              </p>
            </div>
            <div className="toggle-switch">
              <input type="checkbox" id="thirdparty-alert" />
              <label htmlFor="thirdparty-alert"></label>
            </div>
          </div>
          <div className="notification-item">
            <div>
              <span>Newsletter subscription</span>
              <p>Receive email alerts whenever your account is signed in from a new device, browser, or location.</p>
            </div>
            <div className="toggle-switch">
              <input type="checkbox" id="newsletter-alert" />
              <label htmlFor="newsletter-alert"></label>
            </div>
          </div>
        </Element>
      </div>

      {isChangePasswordModal && (
        <div className="changePasswordModal-overlay">
          <div className="modal">
            <div className="headers">
              <h1 className="heading">Change Password</h1>
              <img src={close} onClick={closeChangePasswordModal} />
            </div>

            <form className="form-wrapper" onSubmit={handleVerifyPasswordOtpClick}>
              <div className="form-content">
                <div className="password-container">
                  <div className="password-input-wrapper">
                    <label htmlFor="password">Create password</label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Enter new password"
                      id="password"
                      className="password-input"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    {!password && <img src={info} className="info" onClick={toggleTooltip} />}
                    <img src={eye} className="eye" onClick={togglePasswordVisibility} />
                    {showTooltip && (
                      <div className="password-tooltip">
                        <p>Your password must contain</p>
                        <ul>
                          <li className={passwordValidations.length ? "valid" : ""}>8 or more characters</li>
                          <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
                          <li className={passwordValidations.capital ? "valid" : ""}>1 Capital and 1 Small letter</li>
                          <li className={passwordValidations.specialChar ? "valid" : ""}>
                            At least 1 special character (@,#,$,!, etc.)
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="confirm-password-input-wrapper">
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      placeholder="Confirm new password"
                      className="confirm-password-input"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    <img src={eye} className="eye" onClick={toggleConfirmPasswordVisibility} />
                  </div>
                </div>
                <div className="otp-button-container">
                  <div className="otp-input-wrapper">
                    <label htmlFor="otp">Enter OTP sent to your e-mail</label>
                    <input
                      type="text"
                      placeholder="OTP"
                      id="otp"
                      className="otp-input"
                      onChange={handleOtpChange}
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="button">
                    <button className="otp-button" onClick={handleSendPasswordOtpClick}>
                      Send OTP
                    </button>
                    <button type="submit" className="submit-button">
                      Change password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {isMobileNumberModal && (
        <div className="addMobNum-overlay">
          <div className="modal">
            <div className="headers">
              <h1 className="heading">Change Mobile Number</h1>
              <img src={close} onClick={closeMobileNumberModal} />
            </div>

            <form className="form-wrapper">
              <div className="form-content">
                <div className="mobile-container">
                  <div className="mobile-input-wrapper">
                    <label htmlFor="mobile">Change Mobile Number</label>
                    <input
                      placeholder="Enter your mobile number"
                      id="mobile"
                      className="mobile-input"
                      type="tel"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      required
                      pattern="\+[0-9]{1,3}[0-9]{10,14}"
                      title="Please enter a valid international mobile number with country code, e.g., +919384756283"
                    />
                  </div>
                </div>
                <div className="otp-button-container">
                  <div className="otp-input-wrapper">
                    <label htmlFor="otp">Enter OTP sent to your mobile number</label>
                    <input
                      type="text"
                      placeholder="OTP"
                      id="otp"
                      className="otp-input"
                      value={mobileOtp}
                      onChange={handleMobileOtpChange}
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="button">
                    <button className="otp-button" onClick={handleSendOtpClick}>
                      Send OTP
                    </button>
                    <button className="submit-button" onClick={handleVerifyOtpClick}>
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteProfile;
