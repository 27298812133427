const routes = [
  {
    layout: "AuthLayout",
    path: "/auth",
    isSecure: false,
    childrens: [
      {
        screen: "Login",
        childPath: "/login",
      },
      {
        screen: "Otp",
        childPath: "/otp",
      },
    ],
  },
  {
    layout: "joinOrganization",
    path: "/joinOrganization",
    isSecure: false,
    childrens: [
      {
        screen: "AcceptInvitation",
        childPath: "/:id",
      },
      {
        screen: "SuccessMessage",
        childPath: "/successmessage",
      },
      {
        screen: "ChangePassword",
        childPath: "/mfa",
      },
      {
        screen: "PayNow",
        childPath: "/pn",
      },
    ],
  },
  {
    layout: "PasswordResetLayout",
    path: "/passwordReset",
    isSecure: false,
    childrens: [
      {
        screen: "PasswordReset",
        childPath: "/email",
      },
      {
        screen: "Message",
        childPath: "/message",
      },
      {
        screen: "CreatePasswordReset",
        childPath: "/:referenceNumber",
      },
      {
        screen: "PasswordChangeSuccess",
        childPath: "/success",
      },
    ],
  },
  {
    layout: "SignupLayout",
    path: "/authSignup",
    isSecure: false,
    childrens: [
      {
        screen: "Signup",
        childPath: "/signup",
      },
      {
        screen: "Country",
        childPath: "/create-business-account",
      },
      {
        screen: "EmailSignup",
        childPath: "/email",
      },
      {
        screen: "CreatePassword",
        childPath: "/password",
      },
      {
        screen: "VerifyEmail",
        childPath: "/verifyEmail",
      },
      {
        screen: "Name",
        childPath: "/name",
      },
      {
        screen: "DateOfBirth",
        childPath: "/dateOfBirth",
      },
      {
        screen: "Business",
        childPath: "/business",
      },
      {
        screen: "Success",
        childPath: "/success",
      },
      {
        screen: "ApplicationUnderReview",
        childPath: "/applicationUnderReview",
      },
      {
        screen: "JoinCompany",
        childPath: "/join-company",
      },
      {
        screen: "Verification",
        childPath: "/verification",
      },
    ],
  },
  {
    layout: "MainLayout",
    path: "/main",
    isSecure: false,
    childrens: [
      {
        screen: "Dashboard",
        childPath: "/dashboard",
      },
      {
        screen: "Pay",
        childPath: "/pay",
      },
      {
        screen: "GetPaid",
        childPath: "/get-paid",
      },
      {
        screen: "Forecast",
        childPath: "/forecast",
      },
      {
        screen: "CreateInvoice",
        childPath: "/create-invoice",
      },
      {
        screen: "Transaction",
        childPath: "/transaction",
      },
      {
        screen: "Profile",
        childPath: "/profile",
      },
      {
        screen: "integration",
        childPath: "/integration",
      },
      {
        screen: "networking",
        childPath: "/networking",
      },
      {
        screen: "vendor",
        childPath: "/vendor",
      },
      {
        screen: "notification",
        childPath: "/notification",
      },
      {
        screen: "AddVendor",
        childPath: "/add-vendor",
      },
      {
        screen: "ExpenseCard",
        childPath: "/expense-card",
      },
      {
        screen: "VendorDetails",
        childPath: "/vendor-details/:id/:source",
      },
      {
        screen: "Budgeting",
        childPath: "/budgeting",
      },
      {
        screen: "InvoiceDetails",
        childPath: "/invoice-details",
      },
      {
        screen: "AddEmploye",
        childPath: "/add-employe",
      },
      {
        screen: "InvoiceDetails",
        childPath: "/invoice-details/:id",
      },
      {
        screen: "Email",
        childPath: "/email-notification",
      },
      {
        screen: "ForeCastPayment",
        childPath: "/forecast-payment",
      },
      {
        screen: "Insights",
        childPath: "/insights",
      },
      {
        screen: "ProfitAndLoss",
        childPath: "/profit-loss",
      },
      {
        screen: "AddBankAccount",
        childPath: "/add-bank-account",
      },
      {
        screen: "Accounts",
        childPath: "/accounts",
      },
      {
        screen: "Admin",
        childPath: "/admin",
      },
    ],
  },

  {
    init: "/auth/login",
    path: "/",
  },
  {
    component: "Page404",
    path: "*",
  },
];

export default routes;
