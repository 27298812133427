import React, { useState } from 'react';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { savePassword } from '../../redux/reducers/authSignupSlice';
import info from '../../assets/icons/info-circle-blur.png'
import eye from '../../assets/icons/eye.png'
import Exported from '../../services/endpoints';
import { request } from '../../services';
import close from '../../assets/icons/close-circle.png'

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    capital: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleTooltip = () => {
    setShowTooltip(prevState => !prevState);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validatePassword = (password) => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const capital = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidations({
      length,
      number,
      capital,
      specialChar,
    });

    return length && number && capital && specialChar;
  };

  const handleOtpChange = (e) => {
    const newOtp = e.target.value;
    setOtp(newOtp);
    validatePassword(newOtp);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    if (!isTyping) {
      setIsTyping(true);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    if (!validatePassword(password)) {
      alert("Password does not meet the requirements");
      return;
    }

    dispatch(savePassword(password));
    console.log(password,otpReference,otp)
    acceptinvite(password,otpReference,otp)
    // navigate("/setpassword/successmessage");
  };

  const acceptinvite = async (password,otpReference,otp) => {
    try {
      const res = await request({
        url: "organization/acceptinvite",
        method: Exported.APIMethods.POST,
        data: { newPassword:password,otpReference,otp },
      });
      console.log("acceptinvite response:", res);
      return res;
    } catch (err) {
      console.error("Error checking email existence", err);
      return null;
    }
  };

  let {id} = useParams();
  const otpReference = id;

  return (
    <div className="screen">
      <div className="screen-wrapper">
        <div className='headers'>
            <h1 className='heading'>Change  Password</h1>
            <img src={close}/>
        </div>

        
        <form className="form-wrapper" onSubmit={handleSubmit}>
            <div className='form-content'>
                <div className='password-container'>
                  <div className="password-input-wrapper">
                      <label htmlFor="password">Create password</label>   
                      <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Enter new password"
                      id='password'
                      className="password-input"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      />
                      {!password  && <img src={info} className='info' onClick={toggleTooltip} />}
                      <img src={eye} className='eye' onClick={togglePasswordVisibility} />
                      {showTooltip && (
                      <div className="password-tooltip">
                          <p>Your password must contain</p>
                          <ul>
                          <li className={passwordValidations.length ? "valid" : ""}>8 or more characters</li>
                          <li className={passwordValidations.number ? "valid" : ""}>At least one number</li>
                          <li className={passwordValidations.capital ? "valid" : ""}>1 Capital and 1 Small letter</li>
                          <li className={passwordValidations.specialChar ? "valid" : ""}>At least 1 special character (@,#,$,!, etc.)</li>
                          </ul>
                      </div>
                      )}
                  </div>
                  <div className="confirm-password-input-wrapper">
                  <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm new password"
                  className="confirm-password-input"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  />
                  <img src={eye} className='eye' onClick={toggleConfirmPasswordVisibility} />
                  </div>
                </div>
                <div className='otp-button-container'>
                    <div className='otp-input-wrapper'>
                        <label htmlFor="otp">Enter OTP sent to your e-mail</label>   
                        <input
                        type="text"   
                        placeholder="OTP"
                        id='otp'
                        className="otp-input"
                        onChange={handleOtpChange}
                        required
                        autoComplete="off" 
                        />
                    </div>
                    <div className='button'>
                      <button className='otp-button'>Send OTP</button>
                      <button type="submit" className="submit-button">Change password</button>
                    </div>
                </div>
            </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;