import React, { useState } from "react";
import "./styles.scss";
import searchIcon from "../../assets/icons/admin/search.png";
import addVendorIcon from "../../assets/icons/admin/add-user.png"; // Add vendor icon
import VendorDetailsModal from "./vendorDetailsModal";
import AddVendorModal from "./addVendor";

const vendorsData = [
  {
    name: "Lago Ltd",
    vendorId: "LL012-090424-13",
    email: "name@lago.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Active",
    payables: "£3,444.54",
  },
  {
    name: "Yapily Ltd",
    vendorId: "LL012-090424-12",
    email: "name@yapily.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Active",
    payables: "£3,444.54",
  },
  {
    name: "On Tech LLP",
    vendorId: "LL012-090424-11",
    email: "name@ontech.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Active",
    payables: "£2,500.00",
  },
  {
    name: "Twilio Inc",
    vendorId: "LL012-090424-10",
    email: "name@twilio.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Inactive",
    payables: "£444.50",
  },
  {
    name: "Salt Inc",
    vendorId: "LL012-090424-09",
    email: "name@salt.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Active",
    payables: "£20,205.87",
  },
  {
    name: "Heaven Media",
    vendorId: "LL012-090424-08",
    email: "name@heaven.com",
    phone: "+44 20 7123 4567",
    location: "London, UK",
    status: "Inactive",
    payables: "£777.98",
  },
];

const VendorScreen = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVendors = vendorsData.filter(
    (vendor) =>
      vendor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRowClick = (vendor) => {
    setSelectedVendor(vendor); // Set the selected vendor data
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedVendor(null); // Clear the selected vendor data
  };

  const handleAddVendorClick = () => {
    setIsAddVendorModalOpen(true); // Open the Add Vendor modal
  };

  const handleCloseAddVendorModal = () => {
    setIsAddVendorModalOpen(false); // Close the Add Vendor modal
  };

  return (
    <div className="vendor-screen">
      <div className="header">
        <div className="search-box">
          <img src={searchIcon} alt="Search" />
          <input type="text" placeholder="Search Vendors" value={searchTerm} onChange={handleSearch} />
        </div>
        <button className="header-button" onClick={handleAddVendorClick}>
          <img src={addVendorIcon} alt="Add Vendor" />
          Add Vendor
        </button>
      </div>
      <div className="vendor-table">
        <div className="table-header">
          <div>Name</div>
          <div>Vendor ID</div>
          <div>Contact E-Mail</div>
          <div>Phone</div>
          <div>Location</div>
          <div>Status</div>
          <div>Payables</div>
        </div>
        <div className="table-row-wrapper">
          {filteredVendors.map((vendor, index) => (
            <div className="table-row" key={index} onClick={() => handleRowClick(vendor)}>
              <div className="vendor-info">
                <div className="vendor-name">{vendor.name}</div>
              </div>
              <div>{vendor.vendorId}</div>
              <div>{vendor.email}</div>
              <div>{vendor.phone}</div>
              <div>{vendor.location}</div>
              <div className={`status ${vendor.status.toLowerCase()}`}>{vendor.status}</div>
              <div>{vendor.payables}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="export-options">
        <span>Export Options</span>
      </div>
      {isModalOpen && selectedVendor && (
        <VendorDetailsModal
          onClose={handleCloseModal}
          vendor={selectedVendor} // Pass the selected vendor data to the modal
        />
      )}

      {isAddVendorModalOpen && (
        <AddVendorModal
          onClose={handleCloseAddVendorModal} // Pass the function to close the modal
        />
      )}
    </div>
  );
};

export default VendorScreen;
