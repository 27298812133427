// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.congratulations-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  text-align: center;
}
.congratulations-screen .message {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
}
.congratulations-screen .message .highlight {
  font-family: "Poppins", sans-serif;
  color: #7E57FF;
  font-weight: bold;
  font-size: 1.5rem;
}
.congratulations-screen .proceed-button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  background-color: #7E57FF;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}
.congratulations-screen .proceed-button:hover {
  background-color: #5754d6;
}
.congratulations-screen .proceed-button::after {
  content: "→";
  margin-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/success/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AAEJ;AAAI;EACI,kCAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;AAER;AAAQ;EACI,kCAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AAEZ;AAEI;EACI,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,iCAAA;EACA,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,yBAAA;AAAZ;AAGQ;EACI,YAAA;EACA,mBAAA;AADZ","sourcesContent":[".congratulations-screen {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f5f5f5;\n    text-align: center;\n\n    .message {\n        font-family: \"Poppins\",sans-serif;\n        font-size: 1.5rem;\n        color: #333;\n        margin-bottom: 2rem;\n\n        .highlight {\n            font-family: \"Poppins\",sans-serif;\n            color: #7E57FF;\n            font-weight: bold;\n            font-size: 1.5rem;\n        }\n    }\n\n    .proceed-button {\n        padding: 1rem 2rem;\n        border: none;\n        border-radius: 50px;\n        background-color: #7E57FF;\n        color: white;\n        font-size: 1rem;\n        font-weight: 600;\n        cursor: pointer;\n        transition: background-color 0.3s;\n        display: flex;\n        align-items: center;\n\n        &:hover {\n            background-color: #5754d6;\n        }\n\n        &::after {\n            content: '→';\n            margin-left: 0.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
