import React, { useState } from "react";
import "./styles.scss";
import editIcon from "../../assets/icons/edit.png";
import deleteIcon from "../../assets/icons/trash.png"; // Assume these icons are available

const ContactPersonForm = () => {
  const [contactPersons, setContactPersons] = useState([
    { salutation: "Mr.", firstName: "", lastName: "", email: "", phone: "" },
    { salutation: "Mr.", firstName: "", lastName: "", email: "", phone: "" },
    { salutation: "Mr.", firstName: "", lastName: "", email: "", phone: "" },
  ]);

  const handleAddContactPerson = () => {
    setContactPersons([...contactPersons, { salutation: "Mr.", firstName: "", lastName: "", email: "", phone: "" }]);
  };

  const handleRemoveContactPerson = (index) => {
    const updatedContacts = [...contactPersons];
    updatedContacts.splice(index, 1);
    setContactPersons(updatedContacts);
  };

  const handleInputChange = (index, field, value) => {
    const updatedContacts = [...contactPersons];
    updatedContacts[index][field] = value;
    setContactPersons(updatedContacts);
  };

  return (
    <>
      <div className="contact-person-form">
        <div className="table-header">
          <div>Salutation</div>
          <div>First Name</div>
          <div>Last Name</div>
          <div>E-Mail</div>
          <div>Phone</div>
        </div>
        {contactPersons.map((person, index) => (
          <div className="table-row" key={index}>
            <div>
              <select
                value={person.salutation}
                onChange={(e) => handleInputChange(index, "salutation", e.target.value)}
              >
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                value={person.firstName}
                onChange={(e) => handleInputChange(index, "firstName", e.target.value)}
                placeholder="First Name"
              />
            </div>
            <div>
              <input
                type="text"
                value={person.lastName}
                onChange={(e) => handleInputChange(index, "lastName", e.target.value)}
                placeholder="Last Name"
              />
            </div>
            <div>
              <input
                type="email"
                value={person.email}
                onChange={(e) => handleInputChange(index, "email", e.target.value)}
                placeholder="E-Mail"
              />
            </div>
            <div>
              <select value={person.phone} onChange={(e) => handleInputChange(index, "phone", e.target.value)}>
                <option value="Mobile">Mobile</option>
                <option value="Work Phone">Work Phone</option>
              </select>
            </div>
            <div className="action-icons">
              <img src={editIcon} alt="Edit" className="edit-icon" />
              <img
                src={deleteIcon}
                alt="Delete"
                className="delete-icon"
                onClick={() => handleRemoveContactPerson(index)}
              />
            </div>
          </div>
        ))}
        <div className="add-contact">
          <button type="button" onClick={handleAddContactPerson}>
            <span>+</span> Add Contact Person
          </button>
        </div>
      </div>
      <div className="address-footer">
        <div className="button-group">
          <button className="save-button">Save</button>
          <button className="cancel-button">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default ContactPersonForm;
