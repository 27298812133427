import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveName, saveLastname } from '../../redux/reducers/authSignupSlice';

const NameSignup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName || !lastName) {
      alert("Please enter both first and last names");
      return;
    }

    dispatch(saveName(firstName));
    dispatch(saveLastname(lastName));
    navigate("/authSignup/dateOfBirth"); // Replace with the actual path for the next step
  };

  return (
    <div className="name-screen">
      {/* <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button> */}
      <div className="content-wrapper">
        <h1>Name</h1>
        <p>As in Official ID. We’ll verify your identity</p>
        
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First Name"
            className="name-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            className="name-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <button type="submit" className="continue-button">Continue</button>
        </form>
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default NameSignup;
