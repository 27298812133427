import React, { useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBusinessDetails,
  saveOrgID,
  saveSelectedBusiness,
  saveUserStatus,
} from "../../redux/reducers/authSignupSlice";
import { request } from "../../services";
import Exported from "../../services/endpoints";

const BusinessDetailsSignup = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.authSignup.id);

  const handleSearchChange = async (e) => {
    let query = e.target.value;
    setSearchQuery(query);
    let params = {
      companyPartialName: query,
      itemsPerPage: 10,
      startIndex: 1,
    };
    if (e.target.value.length > 2) {
      try {
        const res = await request({
          url: "companies/search",
          method: Exported.APIMethods.GET,
          params: params,
        });
        setSearchResults(res.data?.companies);
      } catch (err) {
        console.error("Error fetching search results", err);
      }
    } else {
      setSearchResults([]);
    }
  };

  const createOrganisation = async (id, selectedBusiness) => {
    try {
      const res = await request({
        url: "organization",
        method: Exported.APIMethods.POST,
        data: {
          ...selectedBusiness,
          userId: id,
        },
      });
      return res;
    } catch (err) {
      console.error("Error fetching search results", err);
      return null;
    }
  };

  const handleBusinessSelect = (business) => {
    setSelectedBusiness(business);
    setSearchQuery(business.name);
    setSearchResults([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedBusiness) {
      alert("Please select a business from the search results");
      return;
    }
    dispatch(saveSelectedBusiness(selectedBusiness));
    let res = await createOrganisation(id, selectedBusiness);
    dispatch(saveOrgID(res.data?.organization?.id));
    let userStatus = res.data.user?.status;
    dispatch(saveUserStatus(userStatus));
    if (res.data.success) {
      navigate("/authSignup/success");
    } else {
      alert(`${res.data.errorMessage}`);
    }
  };

  return (
    <div className="business-details-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Tell us about your business</h1>
        <p>We need your business details to open the account</p>

        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <div className="search-container">
              <span className="search-icon">🔍</span>
              <input
                type="text"
                className="input-search"
                placeholder="Search business legal name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {searchResults.length > 0 && (
                <div className="search-results">
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className={`search-result ${
                        selectedBusiness && selectedBusiness.legalName === result.name ? "selected" : ""
                      }`}
                      onClick={() => handleBusinessSelect(result)}
                    >
                      <div className="result-name">{result.legalName}</div>
                      <div className="result-number">{result.companyHouseNumber}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              className="input"
              value={selectedBusiness ? selectedBusiness.address.country : ""}
              readOnly
            />
            <label className="input-label">Country of Incorporation</label>
          </div>
          <div className="input-wrapper">
            <input type="text" className="input" value={selectedBusiness ? selectedBusiness.legalName : ""} readOnly />
            <label className="input-label">Business Legal Name</label>
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              className="input"
              value={selectedBusiness ? selectedBusiness.companyHouseNumber : ""}
              readOnly
            />
            <label className="input-label">Companies House Registration Number</label>
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              className="input"
              value={selectedBusiness ? selectedBusiness.dateOfCreation : ""}
              readOnly
            />
            <label className="input-label">Date of Incorporation</label>
          </div>
          <div className="input-wrapper">
            <input type="text" className="input" value={selectedBusiness ? selectedBusiness.type : ""} readOnly />
            <label className="input-label">Business Type</label>
          </div>
          <button type="submit" className="continue-button">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default BusinessDetailsSignup;
