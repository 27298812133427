import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./emailModal.scss";

const EmailModal = () => {
  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);
  const quillRef = useRef(null);

  const handleCcClick = () => {
    setCcVisible(!ccVisible);
  };

  const handleBccClick = () => {
    setBccVisible(!bccVisible);
  };

  useEffect(() => {
    const quill = new Quill(quillRef.current, {
      theme: "snow",
      modules: {
        toolbar: [
          [{ font: [] }, { size: [] }], // Font and Size
          ["bold", "italic", "underline", "strike"], // Toggle formatting buttons
          [{ list: "ordered" }, { list: "bullet" }], // Lists
          [{ align: [] }], // Text alignments
          ["link", "image"], // Link and image
          ["clean"], // Remove formatting
        ],
      },
      placeholder: "Write your message here...",
    });
  }, []);

  return (
    <>
      <div style={{ margin: "20px" }}>E-Mail to Lago Limited</div>
      <div className="email-modal">
        <div className="email-inputs">
          <div className="email-group">
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ color: "#B2BEC3", marginRight: "15px" }}>Send to</label>
              <div className="email-tags">
                <span className="tag">name1@lago.com</span>
                <span className="tag">name2@lago.com</span>
              </div>
            </div>
            <div className="cc-bcc">
              <span className="cc" onClick={handleCcClick}>
                Cc
              </span>
              <span className="bcc" onClick={handleBccClick}>
                Bcc
              </span>
            </div>
          </div>

          {ccVisible && (
            <div className="input-group">
              <label>Cc</label>
              <input type="text" placeholder="Cc recipients" />
            </div>
          )}

          {bccVisible && (
            <div className="input-group">
              <label>Bcc</label>
              <input type="text" placeholder="Bcc recipients" />
            </div>
          )}

          <div className="input-group">
            <label>Subject</label>
            <input type="text" placeholder="Subject Line" />
          </div>

          <div className="input-group">
            <div ref={quillRef} style={{ height: "250px" }} />
          </div>

          <div className="attach-vendor-statement">
            <input type="checkbox" id="attach" />
            <label htmlFor="attach">Attach Vendor Statement</label>
          </div>

          <div className="email-actions">
            <button className="send-button">Send</button>
            <div className="text-options"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailModal;
