import React from 'react';
import './styles.scss';
import clock from '../../assets/icons/clock.png'
import { useSelector } from "react-redux";

const ApplicationUnderReview = () => {

const email = useSelector((state) => state.authSignup.email)
console.log("gfuwegf")
console.log(email)

  return (
    <div className="review-screen">
      <div className="message-top">
        <div className='clock'>
            <img src={clock}/>
        </div>
        <div className='message-content'>
          <div className='highlight'>
            Submission Under Review
          </div>
          <div className='message'>
            Thanks! We’ve received your documents and we are in the process of reviewing it.
          </div>
        </div>
      </div>
      <div className="message-bottom">
        <div className='note-message'>Keep an eye on your email</div>
        <div className='note-list'>
          <ul>
            <li>Be sure to watch email address <span>{email}</span> as we may<br/>  request additional informations  to facilitate the review or get a <br/> confirmation in next 24-48 hours. (Be sure to check spam folder as<br/>  well)</li>
            <li>In the meantime check out our demo or explore the <a href=''>tutorials</a> here.</li>
            <li>We review submissions to ensure compliance with our <a href="" target="_blank">privacy <br /> policy</a> and <a href="/terms-of-service" target="_blank">terms of service</a>.</li>
          </ul>

        </div>
      </div>
    </div>
  );
};

export default ApplicationUnderReview;