import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Exported from '../../services/endpoints';
import { request } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { saveEmail, saveReferenceNumber } from '../../redux/reducers/authSignupSlice';

const EmailSignup = () => {
  const [email, setEmail] = useState("")
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailExists = async (email) => {
    try {
      const res = await request({
        url: "onboard/emailExists",
        method: Exported.APIMethods.GET,
        params: { email },
      });
      console.log("emailExists response:", res);
      return res;
    } catch (err) {
      console.error("Error checking email existence", err);
      return null;
    }
  };

  const emailOtp = async (email) => {
    try {
      const res = await request({
        url: "onboard/sendEmailOtp",
        method: Exported.APIMethods.POST,
        params: { email },
      });
      console.log("emailOtp response:", res);
      return res;
    } catch (err) {
      console.error("Error sending OTP", err);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(saveEmail(email));

    try {
      const emailCheckRes = await emailExists(email);

      console.log("emailCRes:",emailCheckRes);
      if (emailCheckRes && emailCheckRes.data.success===true) {
        setShowMessage(true);
      } 
      else {
        setShowMessage(false);
        const otpRes = await emailOtp(email);
        dispatch(saveReferenceNumber(otpRes.data.referenceNumber));
        if (otpRes && otpRes.data.success) {
          navigate("/authSignup/password");
        } else {
          console.error("Failed to send OTP");
        }
      }
    } 
    catch (err) {
      console.error("An error occurred", err);
    }
  };

  return (
    <div className="email-screen">
      <button className="back-button" onClick={() => navigate(-1)}>
        ←
      </button>
      <div className="content-wrapper">
        <h1>Enter Your Work E-Mail</h1>
        <p>We’ll send you the verification code</p>
        
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your e-mail..."
            className="email-input" 
            onChange={(e)=>setEmail(e.target.value)}
            required
          />
          <button type="submit" className="continue-button">Continue</button>
        </form>
        {showMessage &&
          <div className="email-exists">This email already exists, go back to 
           <span className="email-exists-login" onClick={()=>navigate("/auth/login")}> login </span>
            page
          </div>
        }
      </div>
      <footer className="footer">
        <p className="footer-company">PennyFlo Limited • United Kingdom</p>
        <div className="footer-links">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </footer>
    </div>
  );
};

export default EmailSignup;
